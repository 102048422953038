.rootContainer {
    height: calc(100vh);
    display: flex;
    flex-direction: column;
}

.navBar {
    position: fixed;
    width: 100%;
    z-index: 1;
}