.applicationsContainer{
    position: relative;
    height: 100%;
    width: 100%;
}


.createAppButton{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.appLoader{
    display: grid;
    place-items: center;
    height: calc(100vh - 10.125rem);
}