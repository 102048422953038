.headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-inline: 12px;
}

.headerTitle {
    font-family: poppins !important;
    font-size: 24px;
    font-weight: 600;
    color: #161616;
}

.drawer {
    max-width: 622px;
    width: 622px;
    margin-top: 66px;
    padding: 0;
}

.tabs {
    position: static;
    width: 50px;
    margin-top: 14px;
}

.tab{
    width: 75px;
}
