.summaryContainer{
    width: 622px;
    height: calc(100vh - 11.125rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    background-color: var(--white);
}

.summaryContent{
    padding-top: var(--spacing-6);
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.titleContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--spacing-0-5) var(--spacing-8);
    gap: 74px;
    width: 100%;
}

.summaryStatusContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: var(--spacing-2) var(--spacing-8);
    gap: var(--spacing-8);
    width: 100%;
}

.statusContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--spacing-2) var(--spacing-0);
    gap: var(--spacing-2-5);
}

.statusCount{
    font-family: var(--font-families-source-sans-pro);
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-6);
    line-height: 40px;
    letter-spacing: var(--letter-spacing-0);
    color: var(--text-text-primary);
}

.statusLabel{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-1);
    line-height: 21px;
    color: #293447;
}

.dividerContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-3) var(--spacing-8);
    gap: var(--spacing-2-5);
    width: 100%;
}

.divider{
    height: var(--sizing-px);
    width: 100%;
    background: var(--miscellaneous-skeleton-background);
}

.summaryDetailsContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-2) var(--spacing-8);
    gap: var(--spacing-4);
}

.summaryInfoContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 50vh;
    width: 100%;
    position: relative;
    gap: var(--spacing-6);
    overflow: scroll;
}

.infoContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0);
    gap: var(--spacing-2);
    width: 100%;
}

.infoTitle{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-3);
    line-height: 27px;
    color: var(--text-text-primary);
}

.productListContiner{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0);
    gap: var(--spacing-4);
    width: 100%;
}

.selectedSpaceContainer, .productPermissionsContainer{
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-4);
    gap: var(--spacing-2);
    border: var(--sizing-px) solid var(--layer-accent-layer-accent-01);
    border-radius: var(--border-radius-2);
    width: 100%;
}

.productName{
    font-family: var(--font-families-source-sans-pro);
    font-size: var(--font-size-1);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: var(--letter-spacing-0);
    color: var(--text-text-primary);
}

.tags{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: var(--spacing-0);
    gap: var(--spacing-2);
    flex-wrap: wrap;
}

.emptyStateContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.emptyStateCard{
    padding: var(--spacing-4) !important;
}

.artContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-8);
    gap: var(--spacing-8);
    width: 100%;
}

.rolesDefaultMessage{
    font-family: var(--font-families-source-sans-pro);
    font-size: var(--font-size-1);
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--black);
}

.actionsContainer{
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: var(--spacing-6) var(--spacing-8);
    gap: var(--spacing-4);
    width: 100%;
    height: var(--sizing-20);
    background: var(--white);
    box-shadow: var(--sizing-0) -1px var(--sizing-0-5) rgba(0, 0, 0, 0.05), var(--sizing-0) -1px var(--sizing-1) rgba(0, 0, 0, 0.05), var(--sizing-0) -2px var(--sizing-2) rgba(0, 0, 0, 0.05);
}
