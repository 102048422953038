@import url('@ip-synamedia/syna-ui-library/dist/index.css');
@import url('@ip-synamedia/common-ui-library/dist/styles/_variables.css');
html, body, #root {
  width: 100%;
  height: 100%;
}

/* *{
  font-family: 'Poppins' !important;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    box-sizing: border-box;
}

/* Scrollbar styles  */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c2c2c2;
    opacity: 0.5;
    border-radius: 2.5px;
}
.productiveHeading3 {
  font-family: var(--productive-heading-03-font-family) !important;
  font-weight: var(--productive-heading-03-font-weight);
  line-height: var(--productive-heading-03-line-height);
  font-size: var(--productive-heading-03-font-size);
  letter-spacing: var(--productive-heading-03-letter-spacing);
  text-decoration: var(--productive-heading-03-text-decoration);
  color: var(--gray-100);
}

.productiveHeading4 {
  font-family: var(--productive-heading-04-font-family) !important;
  font-weight: var(--productive-heading-04-font-weight);
  line-height: var(--productive-heading-04-line-height);
  font-size: var(--productive-heading-04-font-size);
  letter-spacing: var(--productive-heading-04-letter-spacing);
  text-decoration: var(--productive-heading-04-text-decoration);
  color: var(--gray-100);
}

.productiveHeading5 {
  font-family: var(--productive-heading-05-font-family) !important;
  font-weight: var(--productive-heading-05-font-weight);
  line-height: var(--productive-heading-05-line-height);
  font-size: var(--productive-heading-05-font-size);
  letter-spacing: var(--productive-heading-05-letter-spacing);
  text-decoration: var(--productive-heading-05-text-decoration);
  color: var(--gray-100);
}

.productiveHeading6 {
  font-family: var(--productive-heading-06-font-family) !important;
  font-size: var(--productive-heading-06-font-size);
  font-weight: var(--productive-heading-06-font-weight);
  line-height: var(--productive-heading-06-line-height);
  letter-spacing: var(--productive-heading-06-letter-spacing);
  text-align: left;
  color: var(--gray-100);
}

.semiBoldShort2 {
  text-align: left;
  font-family: var(--semi-bold-body-short-02-font-family) !important;
  font-size: var(--semi-bold-body-short-02-font-size);
  font-weight: var(--semi-bold-body-short-02-font-weight);
  line-height: var(--semi-bold-body-short-02-line-height);
  letter-spacing: var(--semi-bold-body-short-02-letter-spacing);
  color: var(--gray-100);
  text-decoration: var(--semi-bold-body-short-02-text-decoration);
}

.SemiBoldShort1 {
  font-family: var(--semi-bold-body-short-01-font-family) !important;
  font-weight: var(--semi-bold-body-short-01-font-weight);
  line-height: var(--semi-bold-body-short-01-line-height);
  font-size: var(--semi-bold-body-short-01-font-size);
  letter-spacing: var(--semi-bold-body-short-01-letter-spacing);
  text-decoration: var(--semi-bold-body-short-01-text-decoration);
  color: var(--gray-100);
}

.bodyShort1 {
  font-family:var(--body-short-01-font-family) !important;
  font-weight:var(--body-short-01-font-weight);
  line-height:var(--body-short-01-line-height);
  font-size:var(--body-short-01-font-size);
  letter-spacing:var(--body-short-01-letter-spacing);
  text-decoration:var(--body-short-01-text-decoration);
  color: var(--blue-70);
}

.bodyShort2 {
  font-family: var(--body-short-02-font-family);
  font-weight: var(--body-short-02-font-weight);
  line-height: var(--body-short-02-line-height);
  font-size: var(--body-short-02-font-size);
  letter-spacing: var(--body-short-02-letter-spacing);
  text-decoration: var(--body-short-02-text-decoration);
  color: var(--gray-100);
}

.bodyLong1 {
  font-family:var(--body-long-01-font-family) !important;
  font-weight:var(--body-long-01-font-weight);
  line-height:var(--body-long-01-line-height);
  font-size:var(--body-long-01-font-size);
  letter-spacing:var(--body-long-01-letter-spacing);
  text-decoration:var(--body-long-01-text-decoration);
  color: var(--gray-100);
}