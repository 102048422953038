.root{
    display: flex;
}
.rolesContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.multiselectContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: var(--spacing-0);
    gap: var(--spacing-6);
    width: 674px;
    height: 52px;
}
.closeIcon{
    padding: 4px;
    color: "#8F8F8F";
}
.closeIcon:hover{
    padding: 3px;
	color: "#3380FF";
}