.applicationsContainer{
    padding: 24px 32px 0px;
    height: calc(100vh - 10.125rem);
}

.permissionElement{
    display: flex;
    gap: var(--spacing-2);
}

.extraTags{
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
}

.clientIdContainer{
    display: flex;
    justify-content: space-between;
}

.copyIcon{
    cursor: pointer;
}

.appicaitonsLoader{
    display: grid;
    place-items: center;
    height: calc(100vh - 10.125rem);
}