.rootContainer{
	height: calc(100% - 120px);
	align-items: center;
	justify-content: center;
	display: flex;
}
.col{
	display: flex;
	flex-direction: column;
}
.textContainer{
	display: flex;
	flex-direction: column;
	align-items: center;
}
.colContent{
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 25px;
}
.textUp{
	font-size: 17px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0;
	color: #11161D;
	align-self: center;
}
.text{
	font-size: 17px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0;
	color: #11161D;
}
.icon{
	margin-bottom: 25px
}
.button {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.errorMessage {
	color: red;
	font-size: 16px;
	font-weight: 600;
}