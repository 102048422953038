.root{
    margin-top: 20px;
    height: 100%;
}

.selectAccountContainer{
    position: relative;
    width: 100%;
    height: 100%;
}

.selectAccountInfo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.selectAccountLabel{
    font-size: 25px;
    margin-top: 10px;
}