.cardAuditInfoContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 12px;

}

.cardAuditInfo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 40px;
    width: 100%;
}

.auditInfoText{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #293447;
    font-family: "Source Sans Pro";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
}