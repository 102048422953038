.userProfileContainer{
    display: flex;
    align-items: center;
    
}

.iconItem {
    width: 20px;
    height: 20px;
    fill: inherit;
}

.optionIcon {
    width: 14px;
    height: 14px;
    fill: inherit;
}