.editUsersContainer{
    display: flex;
    height: calc( 100vh - 10.125rem );
    justify-content: space-between;
}

.editFromContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0) var(--spacing-0) var(--spacing-10) var(--spacing-0);
    overflow-y: scroll;
    width: 1042px;
    padding-inline: var(--spacing-8);
    flex-grow: 1;
}

.generalDetailsContainer, .userInputContainer, .editRolesContainer{
    box-sizing: border-box;
    width: 100%;
}

.generalDetailsContainer{ 
    padding: 24px 0px 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.generalDetailsTitle{
    font-family: var(--font-families-poppins);
    font-size: var(----font-size-4);
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: var(--gray-100);
    font-size: 20px;
    display: flex;
    gap: var(--spacing-2);
    align-items: center;
}

.userInputContainer{
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex-wrap: wrap;
    padding-bottom: 24px;
}

.userInputRow{
    display: flex;
    flex-direction: row;
    gap: 32px;
    width: 100%;
    flex-wrap: wrap;
}

 .inputTextContainer{
    padding: 0;
    margin: 0;
 }

 .loaderBackDrop{
    position: fixed;
    width: 100%;
    background: #29292980;
    z-index: 999;
    inset: 0;
    color: white;
    display: grid;
    place-items: center;
}

.required{
    color: var(--red-60);
}

.editRolesContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-2) var(--spacing-0);
    gap: var(--spacing-4);
}

.editRolesHeader{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-4);
    line-height: 30px;
    color: var(--text-text-primary);
}

.asterisk{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-4);
    line-height: 30px;
    color: var(--text-text-error);
}

.accountRolesContainer {
    width: 100%;
    background-color: var(--white);
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-4);
    gap: var(--spacing-2);
}

.accoutRolesTitleContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-2);
}

.accountRolesTitle {
    font-family: var(--font-families-poppins);
    font-size: var(--font-size-1);
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    color: var(--text-text-primary);
}