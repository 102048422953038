.inviteUsersContainer{
    display: flex;
    height: calc( 100vh - 10.125rem );
    justify-content: space-between;
}

.inviteUsersFormContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0) var(--spacing-0) var(--spacing-10) var(--spacing-0);
    overflow-y: scroll;
    width: 1042px;
    padding-inline: var(--spacing-8);
    flex-grow: 1;
}

.formRow{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.formCol{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.paddingTop {
    padding-top: var(--spacing-6);
}

.clearAll{
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
}

.clearAllContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-0) var(--spacing-4);
    gap: var(--spacing-2);
    text-transform: capitalize;
}

.clearAllText{
    font-family: var(--font-families-source-sans-pro);
    font-size: var(--font-size-1);
    font-weight: 400;
    line-height: 18px;
    letter-spacing: var(--letter-spacing-0);
    text-align: center;
    color: var(--text-text-brand);
}

.clearAllIcon{
    color: var(--text-text-brand);
}

.emailContainer, .messageContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0) var(--spacing-0) var(--spacing-6);
    gap: var(--spacing-2);
}

.emailContainer{
    height: 184px;
}

.required{
    color: var(--red-60);
}

.emailLabel{
    font-family: var(--font-families-source-sans-pro);
    font-size: var(--font-size-1);
    font-weight: 400;
    line-height: 18px;
    color: var(--text-text-primary);
}

.textAreaContainer{
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 130px;
}

.messageTextAreaLabel {
    font-family: var(--font-families-source-sans-pro);
    font-size: var(--font-size-1);
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
}

.emailInput{
    border: var(--sizing-px) solid var(--border-border-subtle-00) !important;
    width: 100% !important;
}

.sectionContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-2) var(--spacing-0);
    gap: var(--spacing-4);
}

.sectionHeader{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-4);
    line-height: 30px;
    color: var(--text-text-primary);
}

.asterisk{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-4);
    line-height: 30px;
    color: var(--text-text-error);
}

.accountRolesContainer {
    width: 100%;
    background-color: var(--white);
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-4);
    gap: var(--spacing-2);
}

.accountRolesTitle {
    font-family: var(--font-families-poppins);
    font-size: var(--font-size-1);
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    color: var(--text-text-primary);
}

.loaderBackDrop{
    position: fixed;
    width: 100%;
    background: #29292980;
    z-index: 999;
    inset: 0;
    color: white;
    display: grid;
    place-items: center;
}

.toastLink{
    text-decoration: underline;
    text-transform: uppercase;
    font-family: var(--font-families-source-sans-pro);
    font-size: var(--font-size-0);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: var(--spacing-0);
    color: var(--text-text-primary);
}