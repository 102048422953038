.productSwitcherContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.productSwitcherIcon {
    width: 20px;
    height: 20px;
    fill: inherit;
}

.productSwitcherDropDown {
    display: flex;
    top: 58px;
    align-items: center;
    right: 20px;
    position: absolute;
    height: 304px;
    width: 320px;
    color: #293447;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    flex-direction: column;
    z-index: 4;
    padding: 24px 24px 24px 24px;
}

.productInfoListContainer {
    display: flex;
    /*width: 256px;*/
}

.productInfoRow {
    display: grid;
    grid-template-columns: 80px 80px 80px;
    height: 220px;
    overflow-y: scroll;
    row-gap: 4px;
    border-bottom: 1px solid #D8DEE9;
}

.productInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100px;
    padding: 14px 0 14px 0;
}

.productInfo:hover {
    background: #D6E6FF;
    border-radius: 4px;
}

.productIconSvgContainer {
    /*padding: 12px 16px 12px 16px;*/
    align-self: center;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
}


.productIconSvg {
    height: 48px;
    width: 100%;
    display: flex;
    align-self: start;
}

.productName {
    font-family: 'Source Sans Pro' !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    margin-top: auto;

}

.btnContainer {
    height: 32px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 16px;
}

.btn {
    flex: none;
    order: 1;
    flex-grow: 0;
}

.line {
    position: absolute;
    width: 288px;
    height: 0px;
    left: 16px;
    top: 364px;
    border: 1px solid #D8DEE9;
}

.productInfoError {
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 8px;
    padding: 16px;
    height: 378px;
    border-bottom: 1px solid #D8DEE9;
    color: red;
    font-weight: bold;
}

.manageAccountBtn{
    color: var(--link-link-primary);
    font-family: var(--font-families-source-sans-pro);
    font-weight: 400;
    font-size: var(--font-size-1);
    line-height: 18px;
    letter-spacing: 0.01em;
    text-decoration: none;
}

.manageAccountBtn:hover {
    color: var(--link-link-primary-hover);
}

.manageAccountBtn:active{
    color: var(--link-link-primary-active);
}

