.rootContainer{
    height: calc(100% - 120px);
    align-items: center;
    justify-content: center;
    display: flex;
}

.col{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.colContent{
    display: grid;
    place-items: center;
    gap: 24px
}

.svgImage{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #D6E6FF;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    display: grid;
    place-items: center
}

.icon{
    font-size: 40px
}

.typography{
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    color: #91A3C0
}

.errorMessage{
    color: red;
    font-size: 16px;
    font-weight: 600
}