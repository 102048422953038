.root{
    background: #212B3A;
    color: #ffffff;
    position: relative;
    z-index: 4;
}
.rootLogout{
    background: #212B3A;
    color: #ffffff;
    position: relative;
    z-index: 1;
}

.loaderRoot{
    background: #212B3A;
    color: #ffffff;
    position: relative;
    z-index: 4;
    height: 65px;
    /*padding: 65px 0 0 0;*/
}

.container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: 4rem;
    padding-block: 8px;
    padding-inline: 24px 20px;
}

.list{
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
}

.iconItem{
    width: 20px;
    height: 20px;
    fill: inherit;
}

.items{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-left: 24px;
}

.anchor,.anchor:visited,.anchor:active,.anchor:hover{
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
}

.searchContainer{
    /* position: absolute; */
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
}

.searchbar{
    /* hiding the serch for now */
    display: none !important;
    width: 384px;
    height: 30px;
    border: 1px solid #54698C;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.searchbar > input{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFFFFF;
    border: none;
    background: none;
    width: calc(100% - 20px);
}

.searchbar > input:focus-visible{
    outline: none;
}

.searchbar > input::placeholder{
    color: #91A3C0;
}

.vrSaperatar{
    width: 0px;
    height: 20px;
    border: 0.5px solid #FFFFFF;
}

.btnBlue{
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    padding: 8px 24px;
    background: #2966CC;
    color: #f1f4f7;
    border-radius: 4px;
  }
.leftListContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.rightListContainer, .rightListContainerAuth{
    display: flex;
    flex-direction: row;
    gap: var(--spacing-0-5);
}

.rightListContainerAuth{
    align-items: center;
}

.iconContainer{
    display: flex;
    width: 20px;
    height: 20px;
    align-items: center;
}

.logo{
    margin: 0px;
    display: flex;
    align-items: center;
    margin-right: 59px;
}

.navIndicator{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 14px;
}

.navName{
    font-size: 18px;
    font-weight: 600;
}

.buttonRoot{
    padding: 0;
    max-width: 14px;
    position: absolute;
}

.contactUs{
    display: flex;
    z-index: 2;

}
.contactUs:hover {
    cursor: pointer;
}