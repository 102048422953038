.spaceProductRolesInfoContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-4);
    gap: var(--spacing-4);
    width: 719px;
    height: 100%;
    border-left: var(--spacing-px) solid var(--border-border-subtle-00);
    overflow: auto;
}

.productHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-0);
    gap: var(--spacing-2);
    height: var(--sizing-8);
}

.headerLabel{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-2-5);
    height: var(--sizing-8);
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-1);
    color: var(--text-text-primary);
}

.productRows{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: var(--spacing-0);
    gap: var(--spacing-6);
    flex-wrap: wrap;
    overflow: scroll;
}

.productRow{
    display: flex;
    align-items: flex-start;
    padding: var(--spacing-0);
    gap: var(--spacing-6);
}

.productRoleDropDownContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0);
    gap: var(--spacing-1);
    width: 100%;
    height: 58px;
}