div[data-testid="children"] {
    padding: 0;
    height: 100%;
    padding-bottom: 70px;
    margin-right: 2px;
}

.generalDetailsContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 12px;
}

.generalDetailsHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;
    gap: 10px;
    width: 100%;
}
.generalDetailsTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #11161D;
}

.actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.actionButtons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 32px;
}

.buttonContent{
    display: flex;
    align-items: center;
    gap: 9px;
}
.generalDetailsContentContainer{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 32px;
    padding-bottom: 16px;
}
.generalDetailsValue {
    color: #11161D;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
}
.generalDetailsKey{
    color: #52698E;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
}
.generalDetailsFieldContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 204px;
    gap: 4px;
    padding: 0;
}

.assignedRolesContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 12px;
    gap: 16px;
}

.title{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.spaces{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 100%;
}

.usersRolesContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
}
.emptyUsersComponentContainer{
    margin-top: 160;
    display: flex;
    flex-direction: column;
    align-items: center
}
.emptyIconContainer{
    margin-top: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: overlap;

}
.emptyContentBackgroundIcon{
    grid-area: overlap;
    width:100%;
    height: 100%;
}
.emptyContentIcon{
    grid-area: overlap;
    border-radius: 0;
    margin: 32px 25px 32px 25px;
}
.emptyUsersText{
    margin-top: 12px;
    font-size: 17px;
    font-weight: 400;
    color: #8F8F8F;
}
.loader{
    /* Center vertically and horizontally */
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px; /* apply negative top and left margins to truly center the element */
}
.accountRolesTitle, .spaceRolesTittle{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #11161D;

}
/* .accountRole, .roleContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 6px;
    gap: 4px;
    height: 16px;
    background: #F1F3F7;
    border-radius: 4px;

} */

.tagText{
    font-family: Source Sans Pro;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #11161D;
}

.accountRolesContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px 0px;
    gap: 8px;
    width: 100%;
    border: 1px solid #D8DEE9;
    border-radius: 8px;
}

.rolesLineContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 0px 16px;
    gap: 8px;
    width: 100%;
    flex-wrap: wrap;
}

.projectRolesLineContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.productRolesAdminContainer {
    width: 100%;
    box-shadow: 0px 0px 10px rgba(41, 41, 41, 0.15);
    border-radius: 8px;
    margin-top: 12px;
    padding: 12px 12px 12px 12px
}
.projectRolesContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
}

.projectRolesInfoContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
}

.projectRoleTitle{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #11161D;

}
.projectContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px 0px;
    gap: 8px;
    border: 1px solid #D8DEE9;
    border-radius: 8px;
    width: 100%;
}

.projectRoleWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
}

.projectRole{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 16px;
    gap: 4px;
}
.productTitle{
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #11161D;
}
.error{
    margin-top: 16px;
    color: red;
}