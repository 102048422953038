.crumb{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-family: Source Sans Pro;
    font-size: var(--font-size-1);
    font-weight: 400;
    line-height: 18px;
    letter-spacing: var(--letter-spacing-0);
}

.crumbLabel{
    color: var(--text-text-brand);
    cursor: pointer;
}

.selectedCrumbLabel{
    color: var(--gray-60);
    pointer-events: none;
}

div[data-testid="breadCrumbContainer"] {
    font-size: var(--font-size-1) !important;
}