.pageHeaderContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 32px;
    gap: 12px;
    background: #FFFFFF;
    border-bottom: 1px solid #D8DEE9;
    border-radius: 20px 0px 0px 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.titleContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.textContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    flex: none;
    order: 0;
    flex-grow: 0; 
}

.title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.16px;
    color: #11161D;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.description{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #11161D;
    flex: none;
    order: 1;
    flex-grow: 0;
}