.header, .homeHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    height: 64px;
    padding-inline: 274px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.05),
      0px 2px 8px rgba(0, 0, 0, 0.05);
}

.title, .homeTitle{
    font-family: Poppins !important;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1C2430;
}

.btnPrimary {
    border-radius: 4px;
    padding: 0px 16px 0px 16px;
    background: #2966cc;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: center;
    pointer-events: none;
}

.homeHeader{
    padding-inline: 32px;
}

.homeTitle{
    max-width: 1372px;
    flex: auto;
    margin-inline: auto;
}