div[data-style-id="productPermissions"] > :nth-child(1) {
    flex-direction: row-reverse;
}

div[data-style-id="productPermissions"] > :nth-child(1) > :nth-child(1) > :nth-child(1){
    font-weight: 400 !important;
}

.permissionsContent{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4)
}

.permissionsActionsContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.actionsbuttonsContainer{
    display: flex;
    align-items: center;
    gap: var(--spacing-2);
}

.infoMessageCustomCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-4);
    height: 232px;
    min-width: 384px;
    background: var(--layer-layer-02);
    border: 1px solid var(--border-border-subtle-00);
    border-radius: var(--border-radius-1-5);
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.infoMessageContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-8);
}

.infoMessage{
    font-family: var(--body-long-02-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--body-long-02-font-size);
    line-height: var(--body-long-02-line-height);
    letter-spacing: var(--body-long-02-letter-spacing);
    color: var(--text-text-secondary);

}

.permissionsList{
    display: flex;
    flex-wrap: wrap;
    height: 232px !important;
    overflow: scroll;
    border-radius: var(--border-radius-1-5);
}

.permissionsListContent{
    display: flex;
    overflow: scroll;
    flex-wrap: wrap;
    gap: var(--spacing-2);
}

.permissionCard{
    min-width: max-content !important;
    width: max-content !important;
}
