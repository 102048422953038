.homeContainer {
    padding-top: 64px;
    color: #293447;
    background: #f1f3f7;
    box-sizing: border-box;
}

.title {
    font-size: 18px;
    font-weight: 600;
}

.btnPrimary {
    border-radius: 4px;
    padding: 0px 16px 0px 16px;
    background: #2966cc;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: center;
}

.contentContainer {
    padding-top: 48px;
    padding-bottom: 64px;
}


.contentCenter {
    display: flex;
    align-items: center;
    gap: 48px;
    flex-direction: column;
    padding-inline: 32px;
}

.contentStart {
    display: flex;
    align-items: start;
    gap: 48px;
    flex-direction: column;
    padding-inline: 32px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
    flex-wrap: wrap;
}

.col {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border: none;
    flex-wrap: wrap;
}

