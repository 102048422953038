.root {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    height: var(--sizing-12);
    width: var(--sizing-12);
    fill: var(--header-header-icon);
    border-radius: var(--border-radius-full);
}

.root:hover {
    fill: var(--header-header-icon-on-color);
    background-color: var(--header-header-background-hover);
}

.root:active {
    border: none;
    fill: var(--header-header-icon-on-color);
    background-color: var(--header-header-background-active);
}

.root:focus {
    border: var(--border-width-1) solid var(--header-header-focus);
}

.root:focus-visible {
    outline: none;
}

.root.selected {
    fill: var(--header-header-icon-on-color);
    border: var(--border-width-2) solid var(--header-header-border-selected);
}