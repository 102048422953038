.rootContainer {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 420px;
    margin: 0 auto 16px auto;
}

.formContainer {
    width: 392px;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    padding-bottom: 24px;
}

.userInputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
    margin-left: 24px;
}

.setupAccountButton{
    width: 373px;
    height: 40px !important;
    font-weight: 400;
    margin-left: 24px;
    margin-bottom: 25px;
    font-family: 'Source Sans Pro';
}
.titleTopContainer {
    margin-bottom: 26px;
}
.title {
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: #11161D;
    margin-bottom: 8px;
}
.content {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #293447;
    width: 85%;
    margin-bottom: 12px;
    /*margin-bottom: 12px;*/
}

.label {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #293447;
}

.input {
    width: 373px !important;
}

.inputLabel {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #293447;
}

.backToCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin-top: 12px;

}

.backToRow {
    display: flex;
    flex-direction: row;
    margin-left: 36px;

}

.signIn {
    color: #2966CC;
    margin-left: 3px;
    cursor: pointer
}

.emailBoxContainer {
    display: flex;
    flex-direction: column;
    align-items:center
}