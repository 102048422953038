.listData,
.listData2 {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 16px;
    border-bottom: 1px solid #b8c3d6;
    width: 100%;
    height: 72px;
    margin-top: 8px;
}

.listData2 {
    padding: 16px 0;
}

.listData:last-child,
.listData2:last-child {
    border-bottom: none;
}
.listIcon  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    height:48px;
    width: 48px;
}
.listIcon > img {

    height: 32px;
    width: 32px;
}

.listName {
    font-weight: 600;
}
.listDesc {
    align-self: center;
    width: 308px;
    height: 16px;
    font-family: "Source Sans Pro" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #52698E;
}
