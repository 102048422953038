.listContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-4) var(--spacing-0);
    width: var(--sizing-64);
    height: 100%;
}

.headerLabel{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--spacing-0) var(--spacing-4);
    gap: var(--spacing-2-5);
    height: var(--sizing-8);
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-1);
    color: var(--text-text-primary);
}

.listOpetions{
    width: 100%;
    height: 100%;
    overflow: auto;
}

.listOptionsContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0);
    width: 100%;
}

.listOption{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-0) var(--spacing-4);
    gap: var(--spacing-2-5);
    font-family: var(--font-families-source-sans-pro);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-1);
    line-height: 18px;
    letter-spacing: var(--letter-spacing-0);
    text-transform: capitalize;

    width: 100%;
    height: 40px;
}

.listOption:hover{
    background: var(--background-background-hover);
    color: var(--text-text-primary);
}

.listOption:active{
    background: var(--background-background-active);
}

.selectedlistOption{
    background: var(--background-background-selected);
    color: var(--text-text-primary);
}

.selectedlistOption:hover{
    background: var(--background-background-selected-hover);
}