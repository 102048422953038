.editApplicationContainer{
    display: flex;
    height: calc( 100vh - 10.125rem );
    justify-content: space-between;
}

.editFromContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0) var(--spacing-0) var(--spacing-10) var(--spacing-0);
    overflow-y: scroll;
    width: 1042px;
    padding-inline: var(--spacing-8);
    flex-grow: 1;
}

.applicationDetailsContainer, .userInputContainer, .editPermissionsContainer{
    box-sizing: border-box;
    width: 100%;
}

.applicationDetailsContainer{ 
    padding: 24px 0px 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.applicationDetailsTitle, .editPermissionsHeader{
    font-family: var(--font-families-poppins);
    font-size: var(--font-size-4);
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: var(--gray-100);
    display: flex;
    gap: var(--spacing-2);
    align-items: center;
}

.userInputContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.userInputRow{
    display: flex;
    flex-direction: column;
    width: 100%;
}

 .inputTextContainer{
    padding: 0;
    margin: 0;
    height: 80px;
 }

 .textAreaContainer{
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 130px;
}


 .loaderBackDrop{
    position: fixed;
    width: 100%;
    background: #29292980;
    z-index: 999;
    inset: 0;
    color: white;
    display: grid;
    place-items: center;
}

.required{
    color: var(--red-60);
}

.editPermissionsContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-2) var(--spacing-0);
    gap: var(--spacing-4);
}

.editPermissionsHeader{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-4);
    line-height: 30px;
    color: var(--text-text-primary);
    display: flex;
    gap: var(--spacing-2);
    align-items: center;
}