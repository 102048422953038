.headerContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--spacing-6) var(--spacing-8) var(--spacing-4);
    position: relative;
    background-color: var(--white);
    box-shadow: var(--sizing-0) var(--sizing-px) var(--sizing-0-5) rgba(0, 0, 0, 0.05), var(--sizing-0) var(--sizing-px) var(--sizing-1) rgba(0, 0, 0, 0.05), var(--sizing-0) var(--sizing-1) var(--sizing-2) rgba(0, 0, 0, 0.05);
}

.pageHeaderContainer{
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);
}

.headerTitle{
    font-family: var(--font-families-poppins);
    font-size: var(--font-size-5);
    font-weight: 600;
    line-height: 36px;
    letter-spacing: var(--letter-spacing-0);
    color: var(--text-text-primary);
}