.spacesAndRoalsContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: var(--spacing-0);
    gap: var(--spacing-6);
    flex-wrap: wrap;
    height: fit-content;
    max-height: none;
}

.multiselectContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0);
    gap: var(--spacing-1);
}

.multiselectContainer:hover {
    cursor: pointer;
}

.rolesDisplayName{
    font-family: var(--font-families-source-sans-pro);
    font-size: var(--font-size-1);
    font-weight: 400;
    line-height: 22px;
    letter-spacing: var(--letter-spacing-0);
    color: var(--text-text-primary);
}

.closeIcon {
    padding: var(--spacing-1);
    color: #8F8F8F;
}

.closeIcon:hover {
    padding: 3;
    color: #3380FF;
}