.rootContainer {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.project {
    margin-right: 4px;
    text-transform: capitalize;
}

.tableRootContiner {
    height: calc(100vh - 185px);
}