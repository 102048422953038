.root{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: black;
}

.container{
    width: 952px;
    height: 100vh;
    color: white;
}

.infoContainer {
    padding-top: 286px;
    margin-left: 104px;
}

.infoContainer > .title * {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0.03em;
    text-align: left;
    width: 512px;
}

.logo {
    padding-top: 16px;
    margin-left: 24px;
    font-size: 32px;
}

.infoContainer > .desc {
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: left;
    width: 586px;
    color: #b8c3d6;
    margin-top: 16px;
}

.trustContainer {
    position: absolute;
    bottom: 0;
    width: 663.39px;
    margin-bottom: 36px;
    margin-left: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.trustContainer > .trustedTypography {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #d8dee9;
    width: 150px;
}

.trustContainer > .companyIconsContainer {
    display: flex;
    align-items: center;
    gap: 32px;
}

.requestLink,
.requestLink:hover,
.requestLink:focus,
.requestLink:active{
    color: #ffffff !important;
    font-family: Source Sans Pro !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: left;
    text-decoration: underline;
}

.trialLink {
    color: white;
    margin-right: 5px;
}

.trialContainer{
    display: flex;
    flex-direction: column;
    margin-top: 32px;
}

.trialLinkContainer{
    display: flex;
    flex-direction: row;
}