.rootContainer {
    display: flex;
    flex-direction: column;
    width: 450px;
    margin: 0 auto 120px auto;
    /*margin-right: 12px;*/
    /*margin-bottom: 120px;*/
}

.formContainer {
    margin-top: 20px;
    width: 100%;
    height: 100px;
}

.headerContainer {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    height: 200px;
}
.emailBoxContainer {
    display: flex;
    flex-direction: column;
    align-items:center;
    margin: 95px 0 36px 0;
}

.setupAccountButton{
    width: 83%;
    height: 40px !important;
    font-weight: 400;
    margin-left: 40px;
    margin-bottom: 20px;
    font-family: 'Source Sans Pro';
}

.title {
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: #11161D;
    margin-bottom: 8px;
}

.label {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #293447;
    margin-bottom: 24px;
    width: 100%;
}
.content {

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    font-height: 25px;
    text-align: center;
    color: #293447;
    margin-bottom: 4px;
}


.backToCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 22px;
}

.backToRow {
    display: flex;
    flex-direction: row;
}

.signIn {
    color: #2966CC;
    margin-left: 3px;
    cursor: pointer
}

