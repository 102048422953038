.titleContainerWithIcon {
    display: flex;
    gap: 16px;
    align-items: center;
}

.cardIcon{
    display: flex;
}


.cardTitle {
    font-weight: 600;
}

.titleContainerWithSubTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cardTitleOnly {
    font-size: 38px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #11161d;
}

.cardsubTitle {
    font-family: Poppins !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #293447;
}

.card {
    height: 360px !important;
    width: 436px;
    padding: 32px !important;
}

.cardContainer{
    display: flex;
    flex-direction: column;
    gap: 18px;
}


.listContainer {
    padding: 0;
    display: flex;
    flex-direction: column;
}

.emptyRecentlyUseRoot {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.emptyRecentlyUseText {
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
    color:  #293447;
    height: 20px;
}
.emptyRecentlyUseSVG {
    margin-top: 48px;
    margin-bottom: 20px;
    margin-right: 75px;
    width: 64px;
    height: 64px;
}

.manageProducts {
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #2966cc;
    text-decoration: underline;
    margin-top: 75px;
}

.manageProductsLink {
     text-decoration: none;
     color: inherit;
 }

.errorMessage {
    color: red;
    font-size: 16px;
    font-weight: 600;
}

.cardLoaderContainer {
    margin-left: 40px;
}