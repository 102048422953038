.rootContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.sideMenu {
    width: 235px;
}

div[data-testid="sideMenu"] > :nth-child(1) {
    display: none;
}

div[data-testid="sideMenu"] > :nth-child(2) {
    overflow: auto !important;
}

div[data-testid="sideMenu"] > :nth-child(3) {
    display: none;
}