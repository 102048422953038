.cardDesc {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    height: 100%;
}

.cardLoaderContainer {
    margin-left: 40px;
}

.errorMessage {
    color: red;
    font-size: 16px;
    font-weight: 600;
}

.cardAdditionalInfo {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #52698e;
}

.cardGraphic {
    display: flex;
    align-items: center;
    padding-top: 8px;
}

.vSeparator {
    width: 0px;
    height: 16px;
    border: 1px solid #b8c3d6;
}

.titleContainerWithIcon {
    display: flex;
    gap: 16px;
    align-items: center;
}

.cardIcon{
     display: flex;
 }

.cardTitle {
    font-weight: 600;
}

.titleContainerWithSubTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cardTitleOnly {
    font-weight: 600;
}

.cardsubTitle {
    font-weight: 500;
}

.card {
    height: 135px !important;
    width: 436px;
    padding: unset !important;
}

.cardClickable{
    cursor: pointer;
}

.cardContainer{
    display: flex;
    padding: 16px 24px 24px 24px;
}
.cardContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.cardLink {
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    color: #2966cc;
    text-decoration: underline;
}

