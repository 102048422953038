@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
html, body, #root {
  width: 100%;
  height: 100%;
}

/* *{
  font-family: 'Poppins' !important;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    box-sizing: border-box;
}

/* Scrollbar styles  */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c2c2c2;
    opacity: 0.5;
    border-radius: 2.5px;
}
.productiveHeading3 {
  font-family: var(--productive-heading-03-font-family) !important;
  font-weight: var(--productive-heading-03-font-weight);
  line-height: var(--productive-heading-03-line-height);
  font-size: var(--productive-heading-03-font-size);
  letter-spacing: var(--productive-heading-03-letter-spacing);
  -webkit-text-decoration: var(--productive-heading-03-text-decoration);
          text-decoration: var(--productive-heading-03-text-decoration);
  color: var(--gray-100);
}

.productiveHeading4 {
  font-family: var(--productive-heading-04-font-family) !important;
  font-weight: var(--productive-heading-04-font-weight);
  line-height: var(--productive-heading-04-line-height);
  font-size: var(--productive-heading-04-font-size);
  letter-spacing: var(--productive-heading-04-letter-spacing);
  -webkit-text-decoration: var(--productive-heading-04-text-decoration);
          text-decoration: var(--productive-heading-04-text-decoration);
  color: var(--gray-100);
}

.productiveHeading5 {
  font-family: var(--productive-heading-05-font-family) !important;
  font-weight: var(--productive-heading-05-font-weight);
  line-height: var(--productive-heading-05-line-height);
  font-size: var(--productive-heading-05-font-size);
  letter-spacing: var(--productive-heading-05-letter-spacing);
  -webkit-text-decoration: var(--productive-heading-05-text-decoration);
          text-decoration: var(--productive-heading-05-text-decoration);
  color: var(--gray-100);
}

.productiveHeading6 {
  font-family: var(--productive-heading-06-font-family) !important;
  font-size: var(--productive-heading-06-font-size);
  font-weight: var(--productive-heading-06-font-weight);
  line-height: var(--productive-heading-06-line-height);
  letter-spacing: var(--productive-heading-06-letter-spacing);
  text-align: left;
  color: var(--gray-100);
}

.semiBoldShort2 {
  text-align: left;
  font-family: var(--semi-bold-body-short-02-font-family) !important;
  font-size: var(--semi-bold-body-short-02-font-size);
  font-weight: var(--semi-bold-body-short-02-font-weight);
  line-height: var(--semi-bold-body-short-02-line-height);
  letter-spacing: var(--semi-bold-body-short-02-letter-spacing);
  color: var(--gray-100);
  -webkit-text-decoration: var(--semi-bold-body-short-02-text-decoration);
          text-decoration: var(--semi-bold-body-short-02-text-decoration);
}

.SemiBoldShort1 {
  font-family: var(--semi-bold-body-short-01-font-family) !important;
  font-weight: var(--semi-bold-body-short-01-font-weight);
  line-height: var(--semi-bold-body-short-01-line-height);
  font-size: var(--semi-bold-body-short-01-font-size);
  letter-spacing: var(--semi-bold-body-short-01-letter-spacing);
  -webkit-text-decoration: var(--semi-bold-body-short-01-text-decoration);
          text-decoration: var(--semi-bold-body-short-01-text-decoration);
  color: var(--gray-100);
}

.bodyShort1 {
  font-family:var(--body-short-01-font-family) !important;
  font-weight:var(--body-short-01-font-weight);
  line-height:var(--body-short-01-line-height);
  font-size:var(--body-short-01-font-size);
  letter-spacing:var(--body-short-01-letter-spacing);
  -webkit-text-decoration:var(--body-short-01-text-decoration);
          text-decoration:var(--body-short-01-text-decoration);
  color: var(--blue-70);
}

.bodyShort2 {
  font-family: var(--body-short-02-font-family);
  font-weight: var(--body-short-02-font-weight);
  line-height: var(--body-short-02-line-height);
  font-size: var(--body-short-02-font-size);
  letter-spacing: var(--body-short-02-letter-spacing);
  -webkit-text-decoration: var(--body-short-02-text-decoration);
          text-decoration: var(--body-short-02-text-decoration);
  color: var(--gray-100);
}

.bodyLong1 {
  font-family:var(--body-long-01-font-family) !important;
  font-weight:var(--body-long-01-font-weight);
  line-height:var(--body-long-01-line-height);
  font-size:var(--body-long-01-font-size);
  letter-spacing:var(--body-long-01-letter-spacing);
  -webkit-text-decoration:var(--body-long-01-text-decoration);
          text-decoration:var(--body-long-01-text-decoration);
  color: var(--gray-100);
}

.MatissePortalSideMenu_rootContainer__11beZ {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.MatissePortalSideMenu_sideMenu__18IEW {
    width: 235px;
}

div[data-testid="sideMenu"] > :nth-child(1) {
    display: none;
}

div[data-testid="sideMenu"] > :nth-child(2) {
    overflow: auto !important;
}

div[data-testid="sideMenu"] > :nth-child(3) {
    display: none;
}
.Loader_container__3LFDG {
    position: relative;
    height: inherit;
}

.Loader_loader__1j2Bs {
    position: absolute;
    top: 50%;
    left: 50%;
}
.noActiveUsers_rootContainer__3Xzui{
    height: calc(100% - 120px);
    align-items: center;
    justify-content: center;
    display: flex;
}

.noActiveUsers_col__2aIgj{
    display: flex;
    flex-direction: column;
    grid-gap: 32px;
    gap: 32px;
}

.noActiveUsers_colContent__BjTHl{
    display: grid;
    place-items: center;
    grid-gap: 24px;
    gap: 24px
}

.noActiveUsers_svgImage__3ZtoJ{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #D6E6FF;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    display: grid;
    place-items: center
}

.noActiveUsers_icon__1WgCU{
    font-size: 40px
}

.noActiveUsers_typography__2r0y6{
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    color: #91A3C0
}

.noActiveUsers_errorMessage__uYC4l{
    color: red;
    font-size: 16px;
    font-weight: 600
}
div[data-testid="children"] {
    padding: 0;
    height: 100%;
    padding-bottom: 70px;
    margin-right: 2px;
}

.Details_generalDetailsContainer__quzD_{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 12px;
}

.Details_generalDetailsHeader__r_Xft {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
}
.Details_generalDetailsTitle__ek0dA {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #11161D;
}

.Details_actions__2_78A{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 8px;
    gap: 8px;
}

.Details_actionButtons__iJzqy{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    height: 32px;
}

.Details_buttonContent__3tT9y{
    display: flex;
    align-items: center;
    grid-gap: 9px;
    gap: 9px;
}
.Details_generalDetailsContentContainer__1vcBZ{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-row-gap: 16px;
    row-gap: 16px;
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
    padding-bottom: 16px;
}
.Details_generalDetailsValue__f079s {
    color: #11161D;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
}
.Details_generalDetailsKey__3v8dV{
    color: #52698E;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
}
.Details_generalDetailsFieldContainer__14aHr{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 204px;
    grid-gap: 4px;
    gap: 4px;
    padding: 0;
}

.Details_assignedRolesContainer__e84yw{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 12px;
    grid-gap: 16px;
    gap: 16px;
}

.Details_title__25RNi{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.Details_spaces__39vQC{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 24px;
    gap: 24px;
    width: 100%;
}

.Details_usersRolesContainer__1JmEl{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 8px;
    gap: 8px;
    width: 100%;
}
.Details_emptyUsersComponentContainer__2I8aC{
    margin-top: 160;
    display: flex;
    flex-direction: column;
    align-items: center
}
.Details_emptyIconContainer__2sbqe{
    margin-top: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: overlap;

}
.Details_emptyContentBackgroundIcon__2aIwS{
    grid-area: overlap;
    width:100%;
    height: 100%;
}
.Details_emptyContentIcon__2-pLM{
    grid-area: overlap;
    border-radius: 0;
    margin: 32px 25px 32px 25px;
}
.Details_emptyUsersText__1qHgs{
    margin-top: 12px;
    font-size: 17px;
    font-weight: 400;
    color: #8F8F8F;
}
.Details_loader__VAIbh{
    /* Center vertically and horizontally */
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px; /* apply negative top and left margins to truly center the element */
}
.Details_accountRolesTitle__9p3Lr, .Details_spaceRolesTittle__gFIEz{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #11161D;

}
/* .accountRole, .roleContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 6px;
    gap: 4px;
    height: 16px;
    background: #F1F3F7;
    border-radius: 4px;

} */

.Details_tagText__1L6b3{
    font-family: Source Sans Pro;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #11161D;
}

.Details_accountRolesContainer__1OeVW{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px 0px;
    grid-gap: 8px;
    gap: 8px;
    width: 100%;
    border: 1px solid #D8DEE9;
    border-radius: 8px;
}

.Details_rolesLineContainer__3NkLP{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 0px 16px;
    grid-gap: 8px;
    gap: 8px;
    width: 100%;
    flex-wrap: wrap;
}

.Details_projectRolesLineContainer__3cC8b{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 8px;
    gap: 8px;
}

.Details_productRolesAdminContainer__2qzHv {
    width: 100%;
    box-shadow: 0px 0px 10px rgba(41, 41, 41, 0.15);
    border-radius: 8px;
    margin-top: 12px;
    padding: 12px 12px 12px 12px
}
.Details_projectRolesContainer__1Xnmn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 8px;
    gap: 8px;
    width: 100%;
}

.Details_projectRolesInfoContainer__3hZda{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 16px;
    gap: 16px;
    width: 100%;
}

.Details_projectRoleTitle__3FqLd{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #11161D;

}
.Details_projectContainer__Ca2i_{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px 0px;
    grid-gap: 8px;
    gap: 8px;
    border: 1px solid #D8DEE9;
    border-radius: 8px;
    width: 100%;
}

.Details_projectRoleWrapper__3QClQ{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
}

.Details_projectRole__32y9w{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 16px;
    grid-gap: 4px;
    gap: 4px;
}
.Details_productTitle__vxyLJ{
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #11161D;
}
.Details_error__6UxMG{
    margin-top: 16px;
    color: red;
}
.UserRolesDrawer_headerContainer__2u9aY {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-inline: 12px;
}

.UserRolesDrawer_headerTitle__1_deA {
    font-family: poppins !important;
    font-size: 24px;
    font-weight: 600;
    color: #161616;
}

.UserRolesDrawer_drawer__2YYCX {
    max-width: 622px;
    width: 622px;
    margin-top: 66px;
    padding: 0;
}

.UserRolesDrawer_tabs__eiHL2 {
    position: static;
    width: 50px;
    margin-top: 14px;
}

.UserRolesDrawer_tab__1d1Sn{
    width: 75px;
}

.ActiveUsers_rootContainer__Sf_yS{
height: calc(100vh - 185px);
}

.ActiveUsers_deleteConfirmationBtb__7ypxm{
    display: flex;
    align-items: center;
}
.ActiveUsers_errorContainer__35rjz{
    color: red;
    margin-left: 24px;
}

.ActiveUsers_userName__J4kc-{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #11161D;
}

.ActiveUsers_role__2DXro{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #52698E;
}
.InvitedUsers_rootContainer__2OuYh {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.InvitedUsers_project__238Ve {
    margin-right: 4px;
    text-transform: capitalize;
}

.InvitedUsers_tableRootContiner__8acPY {
    height: calc(100vh - 185px);
}
.NoInvitees_rootContainer__1b8kI{
	height: calc(100% - 120px);
	align-items: center;
	justify-content: center;
	display: flex;
}
.NoInvitees_col__2pLkU{
	display: flex;
	flex-direction: column;
}
.NoInvitees_textContainer__Dn9_R{
	display: flex;
	flex-direction: column;
	align-items: center;
}
.NoInvitees_colContent__2zD_k{
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 25px;
}
.NoInvitees_textUp__3K0hH{
	font-size: 17px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0;
	color: #11161D;
	align-self: center;
}
.NoInvitees_text__3N-u8{
	font-size: 17px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0;
	color: #11161D;
}
.NoInvitees_icon__H7U-u{
	margin-bottom: 25px
}
.NoInvitees_button__3WWAA {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.NoInvitees_errorMessage__mqiiX {
	color: red;
	font-size: 16px;
	font-weight: 600;
}
.AccountUser_root__MFr5E{
    margin-top: 20px;
    height: 100%;
}

.AccountUser_selectAccountContainer__2xiIS{
    position: relative;
    width: 100%;
    height: 100%;
}

.AccountUser_selectAccountInfo__2M_CC{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.AccountUser_selectAccountLabel__xhHiK{
    font-size: 25px;
    margin-top: 10px;
}
.AppBreadcrumbs_crumb__13EEH{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-family: Source Sans Pro;
    font-size: var(--font-size-1);
    font-weight: 400;
    line-height: 18px;
    letter-spacing: var(--letter-spacing-0);
}

.AppBreadcrumbs_crumbLabel__BLcq7{
    color: var(--text-text-brand);
    cursor: pointer;
}

.AppBreadcrumbs_selectedCrumbLabel__2aAGI{
    color: var(--gray-60);
    pointer-events: none;
}

div[data-testid="breadCrumbContainer"] {
    font-size: var(--font-size-1) !important;
}
.Header_headerContainer__3Nnyt{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--spacing-6) var(--spacing-8) var(--spacing-4);
    position: relative;
    background-color: var(--white);
    box-shadow: var(--sizing-0) var(--sizing-px) var(--sizing-0-5) rgba(0, 0, 0, 0.05), var(--sizing-0) var(--sizing-px) var(--sizing-1) rgba(0, 0, 0, 0.05), var(--sizing-0) var(--sizing-1) var(--sizing-2) rgba(0, 0, 0, 0.05);
}

.Header_pageHeaderContainer__2PAqp{
    display: flex;
    flex-direction: column;
    grid-gap: var(--spacing-1);
    gap: var(--spacing-1);
}

.Header_headerTitle__1hPhu{
    font-family: var(--font-families-poppins);
    font-size: var(--font-size-5);
    font-weight: 600;
    line-height: 36px;
    letter-spacing: var(--letter-spacing-0);
    color: var(--text-text-primary);
}
.InviteUsers_inviteUsersRootContainer__3uyLy{
    background-color: var(--miscellaneous-skeleton-background);
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    padding: var(--spacing-0);
}
.InviteUsersForm_inviteUsersContainer__WnelU{
    display: flex;
    height: calc( 100vh - 10.125rem );
    justify-content: space-between;
}

.InviteUsersForm_inviteUsersFormContainer__3xOKH{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0) var(--spacing-0) var(--spacing-10) var(--spacing-0);
    overflow-y: scroll;
    width: 1042px;
    padding-inline: var(--spacing-8);
    flex-grow: 1;
}

.InviteUsersForm_formRow__1dUlg{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.InviteUsersForm_formCol__3ZBNx{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.InviteUsersForm_paddingTop__12Qz0 {
    padding-top: var(--spacing-6);
}

.InviteUsersForm_clearAll__Bv2_9{
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
}

.InviteUsersForm_clearAllContainer__2vzWh{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-0) var(--spacing-4);
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
    text-transform: capitalize;
}

.InviteUsersForm_clearAllText__3MYsf{
    font-family: var(--font-families-source-sans-pro);
    font-size: var(--font-size-1);
    font-weight: 400;
    line-height: 18px;
    letter-spacing: var(--letter-spacing-0);
    text-align: center;
    color: var(--text-text-brand);
}

.InviteUsersForm_clearAllIcon__9k66d{
    color: var(--text-text-brand);
}

.InviteUsersForm_emailContainer__S2Vzo, .InviteUsersForm_messageContainer__nBj06{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0) var(--spacing-0) var(--spacing-6);
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
}

.InviteUsersForm_emailContainer__S2Vzo{
    height: 184px;
}

.InviteUsersForm_required__27joo{
    color: var(--red-60);
}

.InviteUsersForm_emailLabel__88DPk{
    font-family: var(--font-families-source-sans-pro);
    font-size: var(--font-size-1);
    font-weight: 400;
    line-height: 18px;
    color: var(--text-text-primary);
}

.InviteUsersForm_textAreaContainer__15vbx{
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 130px;
}

.InviteUsersForm_messageTextAreaLabel__c6q36 {
    font-family: var(--font-families-source-sans-pro);
    font-size: var(--font-size-1);
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
}

.InviteUsersForm_emailInput__B4aeY{
    border: var(--sizing-px) solid var(--border-border-subtle-00) !important;
    width: 100% !important;
}

.InviteUsersForm_sectionContainer__19yRo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-2) var(--spacing-0);
    grid-gap: var(--spacing-4);
    gap: var(--spacing-4);
}

.InviteUsersForm_sectionHeader__2ddYN{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-4);
    line-height: 30px;
    color: var(--text-text-primary);
}

.InviteUsersForm_asterisk__g9mrr{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-4);
    line-height: 30px;
    color: var(--text-text-error);
}

.InviteUsersForm_accountRolesContainer__3Mk6n {
    width: 100%;
    background-color: var(--white);
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-4);
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
}

.InviteUsersForm_accountRolesTitle__2R1JC {
    font-family: var(--font-families-poppins);
    font-size: var(--font-size-1);
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    color: var(--text-text-primary);
}

.InviteUsersForm_loaderBackDrop__2Mx0Z{
    position: fixed;
    width: 100%;
    background: #29292980;
    z-index: 999;
    inset: 0;
    color: white;
    display: grid;
    place-items: center;
}

.InviteUsersForm_toastLink__1cxz0{
    text-decoration: underline;
    text-transform: uppercase;
    font-family: var(--font-families-source-sans-pro);
    font-size: var(--font-size-0);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: var(--spacing-0);
    color: var(--text-text-primary);
}
.AccountRoles_root__1y5e2{
    display: flex;
}
.AccountRoles_rolesContainer__WyxMJ{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.AccountRoles_multiselectContainer__prMdD{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: var(--spacing-0);
    grid-gap: var(--spacing-6);
    gap: var(--spacing-6);
    width: 674px;
    height: 52px;
}
.AccountRoles_closeIcon__35IRO{
    padding: 4px;
    color: "#8F8F8F";
}
.AccountRoles_closeIcon__35IRO:hover{
    padding: 3px;
	color: "#3380FF";
}
.ProductsPerProjectRoles_productrolesContainter__33Ajp{
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    border-radius: var(--border-radius-1);
    overflow: hidden;
}
.List_listContainer__1iiN4{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-4) var(--spacing-0);
    width: var(--sizing-64);
    height: 100%;
}

.List_headerLabel__2KLzL{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--spacing-0) var(--spacing-4);
    grid-gap: var(--spacing-2-5);
    gap: var(--spacing-2-5);
    height: var(--sizing-8);
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-1);
    color: var(--text-text-primary);
}

.List_listOpetions__3yedI{
    width: 100%;
    height: 100%;
    overflow: auto;
}

.List_listOptionsContainer__ZGgIT{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0);
    width: 100%;
}

.List_listOption__1oWpQ{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-0) var(--spacing-4);
    grid-gap: var(--spacing-2-5);
    gap: var(--spacing-2-5);
    font-family: var(--font-families-source-sans-pro);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-1);
    line-height: 18px;
    letter-spacing: var(--letter-spacing-0);
    text-transform: capitalize;

    width: 100%;
    height: 40px;
}

.List_listOption__1oWpQ:hover{
    background: var(--background-background-hover);
    color: var(--text-text-primary);
}

.List_listOption__1oWpQ:active{
    background: var(--background-background-active);
}

.List_selectedlistOption__2MrLO{
    background: var(--background-background-selected);
    color: var(--text-text-primary);
}

.List_selectedlistOption__2MrLO:hover{
    background: var(--background-background-selected-hover);
}
.Roles_spaceProductRolesInfoContainer__2iOFO{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-4);
    grid-gap: var(--spacing-4);
    gap: var(--spacing-4);
    width: 719px;
    height: 100%;
    border-left: var(--spacing-px) solid var(--border-border-subtle-00);
    overflow: auto;
}

.Roles_productHeader__YZ27y{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-0);
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
    height: var(--sizing-8);
}

.Roles_headerLabel__3bnH_{
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: var(--spacing-2-5);
    gap: var(--spacing-2-5);
    height: var(--sizing-8);
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-1);
    color: var(--text-text-primary);
}

.Roles_productRows__17uqz{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: var(--spacing-0);
    grid-gap: var(--spacing-6);
    gap: var(--spacing-6);
    flex-wrap: wrap;
    overflow: scroll;
}

.Roles_productRow__1Oyem{
    display: flex;
    align-items: flex-start;
    padding: var(--spacing-0);
    grid-gap: var(--spacing-6);
    gap: var(--spacing-6);
}

.Roles_productRoleDropDownContainer__3bxGE{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0);
    grid-gap: var(--spacing-1);
    gap: var(--spacing-1);
    width: 100%;
    height: 58px;
}
.ProductsRoles_spacesAndRoalsContainer__26stz{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: var(--spacing-0);
    grid-gap: var(--spacing-6);
    gap: var(--spacing-6);
    flex-wrap: wrap;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: none;
}

.ProductsRoles_multiselectContainer__1cqcp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0);
    grid-gap: var(--spacing-1);
    gap: var(--spacing-1);
}

.ProductsRoles_multiselectContainer__1cqcp:hover {
    cursor: pointer;
}

.ProductsRoles_rolesDisplayName__f8O6o{
    font-family: var(--font-families-source-sans-pro);
    font-size: var(--font-size-1);
    font-weight: 400;
    line-height: 22px;
    letter-spacing: var(--letter-spacing-0);
    color: var(--text-text-primary);
}

.ProductsRoles_closeIcon__E9BhB {
    padding: var(--spacing-1);
    color: #8F8F8F;
}

.ProductsRoles_closeIcon__E9BhB:hover {
    padding: 3;
    color: #3380FF;
}
.ProductAdminRoles_productAdminRolesContainer__3L2aU{
    width: 100%;
    height: auto;
    background-color: var(--white);
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-4);
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
}

.ProductAdminRoles_productAdminRolesTitle__3kDvO{
    font-family: var(--font-families-poppins);
    font-size: var(--font-size-1);
    font-weight: 600;
    line-height: 22px;
    color: var(--text-text-primary);
}

.ProductAdminRoles_productAdminRolesTitleContainer__35Brx{
    display: flex;
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
    align-items: center;
}

.ProductAdminRoles_rolesContainer__2ezGL{
    display: flex;
}
.ProductAdminRoles_productsRolesContainer__3mhHS{
    height: 100%;
    width: 100%;
    display: flex;
}
.ProductAdminRoles_rolesLabel__28uS1{
    font-size: var(--font-size-2);
}
.ProductAdminRoles_rolesSelections__2UA-B{
    width: 100%;
}
.ProductAdminRoles_iconContainer__nR0bQ{
    margin-left: 5;
}
.ProductAdminRoles_accordionSummary__10RoY{
    display: flex;
    flex-direction: row-reverse;
}
.ProductAdminRoles_rotate__1pFLx{
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
.ProductAdminRoles_rotateSelected__3MVzJ{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.ProductAdminRoles_modify__380D9{
    margin-left: var(--spacing-2);
}
.ProductAdminRoles_labelContainer__2ql4b{
    display:flex;
    flex-direction: row;
    align-items: center
}

.UsersSummary_usersSummaryContainer__3mVfV{
    width: 622px;
    height: calc(100vh - 10.125rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    background-color: var(--white);
}

.UsersSummary_summaryContent__ZRh5L{
    padding-top: var(--spacing-6);
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.UsersSummary_titleContainer__2A22U{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--spacing-0-5) var(--spacing-8);
    grid-gap: 74px;
    gap: 74px;
    width: 100%;
}

.UsersSummary_summaryStatusContainer__qdlHA, .UsersSummary_editSummaryStatusContainer__3y8Dq{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: var(--spacing-2) var(--spacing-8);
    grid-gap: var(--spacing-8);
    gap: var(--spacing-8);
    width: 100%;
}

.UsersSummary_editSummaryStatusContainer__3y8Dq{
    justify-content: flex-start;
}

.UsersSummary_statusContainer__1DEAB{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--spacing-2) var(--spacing-0);
    grid-gap: var(--spacing-2-5);
    gap: var(--spacing-2-5);
}

.UsersSummary_statusCount__3KVjG{
    font-family: var(--font-families-source-sans-pro);
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-6);
    line-height: 40px;
    letter-spacing: var(--letter-spacing-0);
    color: var(--text-text-primary);
}

.UsersSummary_statusLabel__3SyJq{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-1);
    line-height: 21px;
    color: #293447;
}

.UsersSummary_dividerContainer__1y9IK{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-3) var(--spacing-8);
    grid-gap: var(--spacing-2-5);
    gap: var(--spacing-2-5);
    width: 100%;
}

.UsersSummary_divider__2PZK2{
    height: var(--sizing-px);
    width: 100%;
    background: var(--miscellaneous-skeleton-background);
}

.UsersSummary_rolesDetailsContainer__33bR0{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-2) var(--spacing-8);
    grid-gap: var(--spacing-4);
    gap: var(--spacing-4);
}

.UsersSummary_rolesHeader__1m4TJ{
    display: flex;
    flex-direction: column;
    padding: var(--spacing-0);
    grid-gap: var(--spacing-2-5);
    gap: var(--spacing-2-5);
    width: 100%;
}

.UsersSummary_spacesContainer__34huL{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 50vh;
    width: 100%;
    position: relative;
    grid-gap: var(--spacing-6);
    gap: var(--spacing-6);
    overflow: scroll;
}

.UsersSummary_artContainer__2MJJp{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: var(--spacing-6);
    gap: var(--spacing-6);
    width: 100%;
    top: 86px;
}

.UsersSummary_rolesDefaultMessage__2DcG-{
    font-family: var(--font-families-source-sans-pro);
    font-size: var(--font-size-1);
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--black);
}

.UsersSummary_allRolesContainer__14DOT, .UsersSummary_spaceRolesContainer__wG60Q{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0);
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
    width: 100%;
}

.UsersSummary_rolesContainer__2tjHR, .UsersSummary_productRolesContainer__32BUo{
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    padding: var(--spacing-4);
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
    border: var(--sizing-px) solid var(--layer-accent-layer-accent-01);
    border-radius: var(--border-radius-2);
    width: 100%;
}

.UsersSummary_productListContianer__efIRg{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0);
    grid-gap: var(--spacing-4);
    gap: var(--spacing-4);
    width: 100%;
}

.UsersSummary_productRolesContainer__32BUo{
    flex-direction: column;
}

.UsersSummary_productRoleContainer__3bgrb{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0);
}

.UsersSummary_productInfo__3BlDG{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0) var(--spacing-0) var(--spacing-4);
    grid-gap: var(--spacing-1);
    gap: var(--spacing-1);
}

.UsersSummary_productName__H7746{
    font-family: var(--font-families-source-sans-pro);
    font-size: var(--font-size-1);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: var(--letter-spacing-0);
    color: var(--text-text-primary);
}

.UsersSummary_rolesTitle__9zxrD{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-3);
    line-height: 27px;
    color: var(--text-text-primary);
}

.UsersSummary_roleName__jggJ3{
    font-family: var(--font-families-source-sans-pro);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-0);
    line-height: 16px;
    letter-spacing: var(--letter-spacing-0);
    color: var(--text-text-primary);
}

.UsersSummary_tags__1HGns{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: var(--spacing-0);
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
    flex-wrap: wrap;
}

.UsersSummary_actionsContainer__wjKoB{
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: var(--spacing-6) var(--spacing-8);
    grid-gap: var(--spacing-4);
    gap: var(--spacing-4);
    width: 100%;
    height: var(--sizing-20);
    background: var(--white);
    box-shadow: var(--sizing-0) -1px var(--sizing-0-5) rgba(0, 0, 0, 0.05), var(--sizing-0) -1px var(--sizing-1) rgba(0, 0, 0, 0.05), var(--sizing-0) -2px var(--sizing-2) rgba(0, 0, 0, 0.05);
}

.EditUser_editUserRoot__2zZi9{
    background-color: var(--miscellaneous-skeleton-background);
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    padding: var(--spacing-0);
    
}
.EditUserForm_editUsersContainer__kTAUy{
    display: flex;
    height: calc( 100vh - 10.125rem );
    justify-content: space-between;
}

.EditUserForm_editFromContainer__1M4CV{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0) var(--spacing-0) var(--spacing-10) var(--spacing-0);
    overflow-y: scroll;
    width: 1042px;
    padding-inline: var(--spacing-8);
    flex-grow: 1;
}

.EditUserForm_generalDetailsContainer__2pl3C, .EditUserForm_userInputContainer__18OC6, .EditUserForm_editRolesContainer__CNXgD{
    box-sizing: border-box;
    width: 100%;
}

.EditUserForm_generalDetailsContainer__2pl3C{ 
    padding: 24px 0px 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 16px;
    gap: 16px;
}

.EditUserForm_generalDetailsTitle__2vIfS{
    font-family: var(--font-families-poppins);
    font-size: var(----font-size-4);
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: var(--gray-100);
    font-size: 20px;
    display: flex;
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
    align-items: center;
}

.EditUserForm_userInputContainer__18OC6{
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
    flex-wrap: wrap;
    padding-bottom: 24px;
}

.EditUserForm_userInputRow__mW5Fx{
    display: flex;
    flex-direction: row;
    grid-gap: 32px;
    gap: 32px;
    width: 100%;
    flex-wrap: wrap;
}

 .EditUserForm_inputTextContainer__2vrjv{
    padding: 0;
    margin: 0;
 }

 .EditUserForm_loaderBackDrop__360-E{
    position: fixed;
    width: 100%;
    background: #29292980;
    z-index: 999;
    inset: 0;
    color: white;
    display: grid;
    place-items: center;
}

.EditUserForm_required__2H8l3{
    color: var(--red-60);
}

.EditUserForm_editRolesContainer__CNXgD{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-2) var(--spacing-0);
    grid-gap: var(--spacing-4);
    gap: var(--spacing-4);
}

.EditUserForm_editRolesHeader__3Q1Jq{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-4);
    line-height: 30px;
    color: var(--text-text-primary);
}

.EditUserForm_asterisk__2z7WC{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-4);
    line-height: 30px;
    color: var(--text-text-error);
}

.EditUserForm_accountRolesContainer__1YDCQ {
    width: 100%;
    background-color: var(--white);
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-4);
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
}

.EditUserForm_accoutRolesTitleContainer__3JtqB{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
}

.EditUserForm_accountRolesTitle__3e-fn {
    font-family: var(--font-families-poppins);
    font-size: var(--font-size-1);
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    color: var(--text-text-primary);
}
.Applications_applicationsContainer__1AhfK{
    position: relative;
    height: 100%;
    width: 100%;
}


.Applications_createAppButton__27fc2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.Applications_appLoader__3Y4sd{
    display: grid;
    place-items: center;
    height: calc(100vh - 10.125rem);
}
.PageHeader_pageHeaderContainer__3RLo3{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 32px;
    grid-gap: 12px;
    gap: 12px;
    background: #FFFFFF;
    border-bottom: 1px solid #D8DEE9;
    border-radius: 20px 0px 0px 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.PageHeader_titleContainer__9decP{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.PageHeader_textContainer__3Tk4x{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 4px;
    gap: 4px;
    flex: none;
    order: 0;
    flex-grow: 0; 
}

.PageHeader_title__16GkS{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.16px;
    color: #11161D;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.PageHeader_description__3_z7o{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #11161D;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.ApplicationsContainer_applicationsContainer__30wH1{
    padding: 24px 32px 0px;
    height: calc(100vh - 10.125rem);
}

.ApplicationsContainer_permissionElement__3Gk0n{
    display: flex;
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
}

.ApplicationsContainer_extraTags__2ljUw{
    display: flex;
    flex-direction: column;
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
}

.ApplicationsContainer_clientIdContainer__3RDj3{
    display: flex;
    justify-content: space-between;
}

.ApplicationsContainer_copyIcon__3eya2{
    cursor: pointer;
}

.ApplicationsContainer_appicaitonsLoader__3odSI{
    display: grid;
    place-items: center;
    height: calc(100vh - 10.125rem);
}
.EditApplication_editApplicationRoot__2i2K5{
    background-color: var(--miscellaneous-skeleton-background);
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    padding: var(--spacing-0);
    
}
.EditApplicationForm_editApplicationContainer__18iDy{
    display: flex;
    height: calc( 100vh - 10.125rem );
    justify-content: space-between;
}

.EditApplicationForm_editFromContainer__2xGXD{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0) var(--spacing-0) var(--spacing-10) var(--spacing-0);
    overflow-y: scroll;
    width: 1042px;
    padding-inline: var(--spacing-8);
    flex-grow: 1;
}

.EditApplicationForm_applicationDetailsContainer__1tSIy, .EditApplicationForm_userInputContainer__2PJUy, .EditApplicationForm_editPermissionsContainer__1ZpX8{
    box-sizing: border-box;
    width: 100%;
}

.EditApplicationForm_applicationDetailsContainer__1tSIy{ 
    padding: 24px 0px 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 16px;
    gap: 16px;
}

.EditApplicationForm_applicationDetailsTitle__eQEc1, .EditApplicationForm_editPermissionsHeader__1wYDt{
    font-family: var(--font-families-poppins);
    font-size: var(--font-size-4);
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: var(--gray-100);
    display: flex;
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
    align-items: center;
}

.EditApplicationForm_userInputContainer__2PJUy{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.EditApplicationForm_userInputRow__1CSDf{
    display: flex;
    flex-direction: column;
    width: 100%;
}

 .EditApplicationForm_inputTextContainer__2AHlY{
    padding: 0;
    margin: 0;
    height: 80px;
 }

 .EditApplicationForm_textAreaContainer__1UEGC{
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 130px;
}


 .EditApplicationForm_loaderBackDrop__1LcZ2{
    position: fixed;
    width: 100%;
    background: #29292980;
    z-index: 999;
    inset: 0;
    color: white;
    display: grid;
    place-items: center;
}

.EditApplicationForm_required__ONnzP{
    color: var(--red-60);
}

.EditApplicationForm_editPermissionsContainer__1ZpX8{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-2) var(--spacing-0);
    grid-gap: var(--spacing-4);
    gap: var(--spacing-4);
}

.EditApplicationForm_editPermissionsHeader__1wYDt{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-4);
    line-height: 30px;
    color: var(--text-text-primary);
    display: flex;
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
    align-items: center;
}
.ApplicationSummary_summaryContainer__1m7vO{
    width: 622px;
    height: calc(100vh - 11.125rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    background-color: var(--white);
}

.ApplicationSummary_summaryContent__2pKx2{
    padding-top: var(--spacing-6);
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.ApplicationSummary_titleContainer__1RQBw{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--spacing-0-5) var(--spacing-8);
    grid-gap: 74px;
    gap: 74px;
    width: 100%;
}

.ApplicationSummary_summaryStatusContainer__2MZCo{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: var(--spacing-2) var(--spacing-8);
    grid-gap: var(--spacing-8);
    gap: var(--spacing-8);
    width: 100%;
}

.ApplicationSummary_statusContainer__BHETq{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--spacing-2) var(--spacing-0);
    grid-gap: var(--spacing-2-5);
    gap: var(--spacing-2-5);
}

.ApplicationSummary_statusCount__13hMS{
    font-family: var(--font-families-source-sans-pro);
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-6);
    line-height: 40px;
    letter-spacing: var(--letter-spacing-0);
    color: var(--text-text-primary);
}

.ApplicationSummary_statusLabel__qOBwO{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-1);
    line-height: 21px;
    color: #293447;
}

.ApplicationSummary_dividerContainer__1VpRm{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-3) var(--spacing-8);
    grid-gap: var(--spacing-2-5);
    gap: var(--spacing-2-5);
    width: 100%;
}

.ApplicationSummary_divider__zgKwq{
    height: var(--sizing-px);
    width: 100%;
    background: var(--miscellaneous-skeleton-background);
}

.ApplicationSummary_summaryDetailsContainer__25uLe{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-2) var(--spacing-8);
    grid-gap: var(--spacing-4);
    gap: var(--spacing-4);
}

.ApplicationSummary_summaryInfoContainer__1DU8a{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 50vh;
    width: 100%;
    position: relative;
    grid-gap: var(--spacing-6);
    gap: var(--spacing-6);
    overflow: scroll;
}

.ApplicationSummary_infoContainer__3gg0O{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0);
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
    width: 100%;
}

.ApplicationSummary_infoTitle__31u8C{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-3);
    line-height: 27px;
    color: var(--text-text-primary);
}

.ApplicationSummary_productListContiner__2iIig{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0);
    grid-gap: var(--spacing-4);
    gap: var(--spacing-4);
    width: 100%;
}

.ApplicationSummary_selectedSpaceContainer__f9JpP, .ApplicationSummary_productPermissionsContainer__214LM{
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-4);
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
    border: var(--sizing-px) solid var(--layer-accent-layer-accent-01);
    border-radius: var(--border-radius-2);
    width: 100%;
}

.ApplicationSummary_productName__3FOG9{
    font-family: var(--font-families-source-sans-pro);
    font-size: var(--font-size-1);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: var(--letter-spacing-0);
    color: var(--text-text-primary);
}

.ApplicationSummary_tags__1Qfn6{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: var(--spacing-0);
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
    flex-wrap: wrap;
}

.ApplicationSummary_emptyStateContainer__3QcrK{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ApplicationSummary_emptyStateCard__1x0Av{
    padding: var(--spacing-4) !important;
}

.ApplicationSummary_artContainer__2wJGD{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-8);
    grid-gap: var(--spacing-8);
    gap: var(--spacing-8);
    width: 100%;
}

.ApplicationSummary_rolesDefaultMessage__1gCnH{
    font-family: var(--font-families-source-sans-pro);
    font-size: var(--font-size-1);
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--black);
}

.ApplicationSummary_actionsContainer__UF7-e{
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: var(--spacing-6) var(--spacing-8);
    grid-gap: var(--spacing-4);
    gap: var(--spacing-4);
    width: 100%;
    height: var(--sizing-20);
    background: var(--white);
    box-shadow: var(--sizing-0) -1px var(--sizing-0-5) rgba(0, 0, 0, 0.05), var(--sizing-0) -1px var(--sizing-1) rgba(0, 0, 0, 0.05), var(--sizing-0) -2px var(--sizing-2) rgba(0, 0, 0, 0.05);
}

div[data-style-id="productPermissions"] > :nth-child(1) {
    flex-direction: row-reverse;
}

div[data-style-id="productPermissions"] > :nth-child(1) > :nth-child(1) > :nth-child(1){
    font-weight: 400 !important;
}

.CustomList_permissionsContent__2oUFm{
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: var(--spacing-4);
    gap: var(--spacing-4)
}

.CustomList_permissionsActionsContainer__3ckXC{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.CustomList_actionsbuttonsContainer__3WcLc{
    display: flex;
    align-items: center;
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
}

.CustomList_infoMessageCustomCard__2KvTK{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: var(--spacing-4);
    gap: var(--spacing-4);
    height: 232px;
    min-width: 384px;
    background: var(--layer-layer-02);
    border: 1px solid var(--border-border-subtle-00);
    border-radius: var(--border-radius-1-5);
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.CustomList_infoMessageContainer__1uvRf{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-8);
}

.CustomList_infoMessage__3xe-P{
    font-family: var(--body-long-02-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--body-long-02-font-size);
    line-height: var(--body-long-02-line-height);
    letter-spacing: var(--body-long-02-letter-spacing);
    color: var(--text-text-secondary);

}

.CustomList_permissionsList__2gwcQ{
    display: flex;
    flex-wrap: wrap;
    height: 232px !important;
    overflow: scroll;
    border-radius: var(--border-radius-1-5);
}

.CustomList_permissionsListContent__1bI3T{
    display: flex;
    overflow: scroll;
    flex-wrap: wrap;
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
}

.CustomList_permissionCard__1_FYa{
    min-width: -webkit-max-content !important;
    min-width: max-content !important;
    width: -webkit-max-content !important;
    width: max-content !important;
}

.CreateApplication_createApplicationRoot__1Sikh{
    background-color: var(--miscellaneous-skeleton-background);
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    padding: var(--spacing-0);
}
.CreateApplicationContainer_createApplicationContainer__C1MvY{
    display: flex;
    height: calc( 100vh - 10.125rem );
    justify-content: space-between;
}

.CreateApplicationContainer_createApplicationFrom__3oh6d{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-0) var(--spacing-0) var(--spacing-10) var(--spacing-0);
    overflow-y: scroll;
    width: 1042px;
    padding-inline: var(--spacing-8);
    flex-grow: 1;
}

.CreateApplicationContainer_applicationDetailsContainer__YENQz, .CreateApplicationContainer_userInputContainer__2r3bC, .CreateApplicationContainer_permissionsContainer__2XC2i{
    box-sizing: border-box;
    width: 100%;
}

.CreateApplicationContainer_applicationDetailsContainer__YENQz{ 
    padding: 24px 0px 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 16px;
    gap: 16px;
}

.CreateApplicationContainer_applicationDetailsTitle__1pVTd, .CreateApplicationContainer_permissionsHeader__2SIaJ{
    font-family: var(--font-families-poppins);
    font-size: var(--font-size-4);
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: var(--gray-100);
    display: flex;
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
    align-items: center;
}

.CreateApplicationContainer_userInputContainer__2r3bC{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.CreateApplicationContainer_userInputRow__1Yzho{
    display: flex;
    flex-direction: column;
    width: 100%;
}

 .CreateApplicationContainer_inputTextContainer__1TbGH{
    padding: 0;
    margin: 0;
    height: 80px;
 }

 .CreateApplicationContainer_textAreaContainer__3NXJy{
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 130px;
}


 .CreateApplicationContainer_loaderBackDrop__1Nfn8{
    position: fixed;
    width: 100%;
    background: #29292980;
    z-index: 999;
    inset: 0;
    color: white;
    display: grid;
    place-items: center;
}

.CreateApplicationContainer_required__1MOng{
    color: var(--red-60);
}

.CreateApplicationContainer_spaceSelectionContainer__3u_Pz{
    display: flex;
    flex-direction: column;
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
    height: 102px;
}

.CreateApplicationContainer_spaceSelectionLabel__2KVqI{
    font-family: var(--label-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--label-font-size);
    line-height: var(--label-line-height);
    letter-spacing: var(--label-letter-spacing);
    color: var(--text-text-secondary);
}

.CreateApplicationContainer_spaceSelectionList__2nUA8{
    display: flex;
    grid-gap: var(--spacing-4);
    gap: var(--spacing-4);
}

.CreateApplicationContainer_spaceCard__MTJd7{
    min-width: -webkit-max-content !important;
    min-width: max-content !important;
}

.CreateApplicationContainer_permissionsContainer__2XC2i{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-2) var(--spacing-0);
    grid-gap: var(--spacing-4);
    gap: var(--spacing-4);
}

.CreateApplicationContainer_permissionsHeader__2SIaJ{
    font-family: var(--font-families-poppins);
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-4);
    line-height: 30px;
    color: var(--text-text-primary);
    display: flex;
    grid-gap: var(--spacing-2);
    gap: var(--spacing-2);
    align-items: center;
}

div[data-testid="PopUp_description"] {
    width: 100%;
}

.CreateApplicationContainer_descriptionContianer__3jPq1{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.CreateApplicationContainer_descriptionContent__3ugFA{
    display: flex;
    flex-direction: column;
    grid-gap: var(--spacing-6);
    gap: var(--spacing-6);
}

.CreateApplicationContainer_inputFiled__1p0Y_{
    width: 100%;
}
.Audit_rootContainer__WIals {
    padding: 30px 30px 30px 32px;
    width: 100%;
    min-height: 900px;
    background-color: #D8DEE9
}

.Audit_cardContainer__2wr44 {
    width: 760px;
    height: 328px;
    border: 1px solid #D8DEE9;
    border-radius: 6px;
    background-color: #FFFFFF;
    padding: 24px
}

.Audit_title__3dNXf {
    color: #11161D;
    font-weight: 600;
    font-family: "Source Sans Pro";
    font-size: 16px;

}

.Audit_instruction__B42pA {
    display: flex;
    flex-direction: row;
    margin-top: 12px

}

.Audit_rangeTitle__3eft_ {
    color: #455878;
    font-family: "Source Sans Pro";
    font-size: 12px;
}

.Audit_rangeRequire__1HBGX {
    color: #DA1E28;
    font-size: 12px;
}

.Audit_rangesContainer__3pkvB {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 8px;
}

.Audit_rangeCardContainer__3MX6q {
    height: 52px;
    margin: 0 16px 0 0;
    padding: 6px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Audit_actionsContainer__10a0N {
    display: flex;
    justify-content: space-between;
}
.Audit_optionsContainer__1MQlf{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 230px;
}

.Audit_loaderBackDrop__158iE {
    position: fixed;
    width: 100%;
    background: #29292980;
    z-index: 999;
    inset: 0;
    color: white;
    display: grid;
    place-items: center;
}

.Audit_row__2SN0i {
    display: flex;
    flex-direction: row;
}

.Audit_datePickerInput__m5IhO {
    width: 100%;
    height: 32px;
    border: 1px solid #D8DEE9;
    border-radius: 4px;
    padding: 7px 16px 7px 16px;
    position: absolute;
    grid-column-start: 1;
    grid-row-start: 1;
    top: -25px

}

.Audit_datePickerWrapper__2qypU {
    width: 100%;
    grid-row: 1;
    grid-column: 1;
    background-color: white !important;
}
.Audit_datePickerLabel__oH7M8{
    font-family: "Source Sans Pro";
    color: #455878;
    font-size: 12px;
    margin-bottom: 12px;
}
.Audit_datePickerContainerStart__uscwF{
    display: flex;
    flex-direction: column;
    width: 50%;

}
.Audit_datePickerContainerEnd__3bT-Y{
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    width: 50%;

}

.Audit_grid__3nZ4W{
    display: grid;
    grid-template-columns: 337px;
    grid-template-rows: auto;
    position: relative;
}

.Audit_calendarIcon__2vSKw {
    grid-column-start: 1;
    grid-row-start: 1;
    position: absolute;
    right: 20px;
    top: -3px

}

div[class='react-datepicker__header '] {
    background-color: white !important;
    border-bottom : none !important;
}
div[class='react-datepicker__triangle'] {
    display: none !important;
}
div[class='react-datepicker__current-month'] {
    color: #11161D !important;
    font-family: "Source Sans Pro" !important;
    font-weight: 600 !important;
    font-size: 16px !important;

}

div[class='react-datepicker__day-name'] {
    color: #455878 !important;
}


.NavBar_root__1aiNC{
    background: #212B3A;
    color: #ffffff;
    position: relative;
    z-index: 4;
}
.NavBar_rootLogout__YX5Kr{
    background: #212B3A;
    color: #ffffff;
    position: relative;
    z-index: 1;
}

.NavBar_loaderRoot__G_ZSu{
    background: #212B3A;
    color: #ffffff;
    position: relative;
    z-index: 4;
    height: 65px;
    /*padding: 65px 0 0 0;*/
}

.NavBar_container__3eHrS{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: 4rem;
    padding-block: 8px;
    padding-inline: 24px 20px;
}

.NavBar_list__3gaWA{
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    grid-gap: 24px;
    gap: 24px;
}

.NavBar_iconItem__2dI2N{
    width: 20px;
    height: 20px;
    fill: inherit;
}

.NavBar_items__2Z5ph{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-left: 24px;
}

.NavBar_anchor__lw3GP,.NavBar_anchor__lw3GP:visited,.NavBar_anchor__lw3GP:active,.NavBar_anchor__lw3GP:hover{
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
}

.NavBar_searchContainer__38nEa{
    /* position: absolute; */
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
}

.NavBar_searchbar__3qrkC{
    /* hiding the serch for now */
    display: none !important;
    width: 384px;
    height: 30px;
    border: 1px solid #54698C;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.NavBar_searchbar__3qrkC > input{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFFFFF;
    border: none;
    background: none;
    width: calc(100% - 20px);
}

.NavBar_searchbar__3qrkC > input:focus-visible{
    outline: none;
}

.NavBar_searchbar__3qrkC > input::-webkit-input-placeholder{
    color: #91A3C0;
}

.NavBar_searchbar__3qrkC > input::placeholder{
    color: #91A3C0;
}

.NavBar_vrSaperatar__3Hqbe{
    width: 0px;
    height: 20px;
    border: 0.5px solid #FFFFFF;
}

.NavBar_btnBlue__1-c9F{
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    padding: 8px 24px;
    background: #2966CC;
    color: #f1f4f7;
    border-radius: 4px;
  }
.NavBar_leftListContainer__X9tnS{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.NavBar_rightListContainer__1agwK, .NavBar_rightListContainerAuth__3niRw{
    display: flex;
    flex-direction: row;
    grid-gap: var(--spacing-0-5);
    gap: var(--spacing-0-5);
}

.NavBar_rightListContainerAuth__3niRw{
    align-items: center;
}

.NavBar_iconContainer__2ksiQ{
    display: flex;
    width: 20px;
    height: 20px;
    align-items: center;
}

.NavBar_logo__3hXp8{
    margin: 0px;
    display: flex;
    align-items: center;
    margin-right: 59px;
}

.NavBar_navIndicator__2uHqf{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 14px;
    gap: 14px;
}

.NavBar_navName__MOK_K{
    font-size: 18px;
    font-weight: 600;
}

.NavBar_buttonRoot__18SAx{
    padding: 0;
    max-width: 14px;
    position: absolute;
}

.NavBar_contactUs__1w0U9{
    display: flex;
    z-index: 2;

}
.NavBar_contactUs__1w0U9:hover {
    cursor: pointer;
}
.ProductSwitcher_productSwitcherContainer__3d4Iy {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.ProductSwitcher_productSwitcherIcon__PhDSx {
    width: 20px;
    height: 20px;
    fill: inherit;
}

.ProductSwitcher_productSwitcherDropDown__1K-UY {
    display: flex;
    top: 58px;
    align-items: center;
    right: 20px;
    position: absolute;
    height: 304px;
    width: 320px;
    color: #293447;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    flex-direction: column;
    z-index: 4;
    padding: 24px 24px 24px 24px;
}

.ProductSwitcher_productInfoListContainer__3KUU- {
    display: flex;
    /*width: 256px;*/
}

.ProductSwitcher_productInfoRow__3b0gI {
    display: grid;
    grid-template-columns: 80px 80px 80px;
    height: 220px;
    overflow-y: scroll;
    grid-row-gap: 4px;
    row-gap: 4px;
    border-bottom: 1px solid #D8DEE9;
}

.ProductSwitcher_productInfo__14M-c {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100px;
    padding: 14px 0 14px 0;
}

.ProductSwitcher_productInfo__14M-c:hover {
    background: #D6E6FF;
    border-radius: 4px;
}

.ProductSwitcher_productIconSvgContainer__3O-bF {
    /*padding: 12px 16px 12px 16px;*/
    align-self: center;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
}


.ProductSwitcher_productIconSvg__1ccOn {
    height: 48px;
    width: 100%;
    display: flex;
    align-self: start;
}

.ProductSwitcher_productName__2wTLr {
    font-family: 'Source Sans Pro' !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    margin-top: auto;

}

.ProductSwitcher_btnContainer__Getv1 {
    height: 32px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 16px;
}

.ProductSwitcher_btn__39LVA {
    flex: none;
    order: 1;
    flex-grow: 0;
}

.ProductSwitcher_line__b9ywR {
    position: absolute;
    width: 288px;
    height: 0px;
    left: 16px;
    top: 364px;
    border: 1px solid #D8DEE9;
}

.ProductSwitcher_productInfoError__eUL4m {
    display: flex;
    flex-direction: column;
    align-content: center;
    grid-gap: 8px;
    gap: 8px;
    padding: 16px;
    height: 378px;
    border-bottom: 1px solid #D8DEE9;
    color: red;
    font-weight: bold;
}

.ProductSwitcher_manageAccountBtn__3RcIH{
    color: var(--link-link-primary);
    font-family: var(--font-families-source-sans-pro);
    font-weight: 400;
    font-size: var(--font-size-1);
    line-height: 18px;
    letter-spacing: 0.01em;
    text-decoration: none;
}

.ProductSwitcher_manageAccountBtn__3RcIH:hover {
    color: var(--link-link-primary-hover);
}

.ProductSwitcher_manageAccountBtn__3RcIH:active{
    color: var(--link-link-primary-active);
}


.NavBarIconButton_root__3nlyM {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    height: var(--sizing-12);
    width: var(--sizing-12);
    fill: var(--header-header-icon);
    border-radius: var(--border-radius-full);
}

.NavBarIconButton_root__3nlyM:hover {
    fill: var(--header-header-icon-on-color);
    background-color: var(--header-header-background-hover);
}

.NavBarIconButton_root__3nlyM:active {
    border: none;
    fill: var(--header-header-icon-on-color);
    background-color: var(--header-header-background-active);
}

.NavBarIconButton_root__3nlyM:focus {
    border: var(--border-width-1) solid var(--header-header-focus);
}

.NavBarIconButton_root__3nlyM:focus-visible {
    outline: none;
}

.NavBarIconButton_root__3nlyM.NavBarIconButton_selected__32L24 {
    fill: var(--header-header-icon-on-color);
    border: var(--border-width-2) solid var(--header-header-border-selected);
}
.UserProfile_userProfileContainer__B1lX7{
    display: flex;
    align-items: center;
    
}

.UserProfile_iconItem__XVlxy {
    width: 20px;
    height: 20px;
    fill: inherit;
}

.UserProfile_optionIcon__23e_g {
    width: 14px;
    height: 14px;
    fill: inherit;
}
.HelpDoc_userProfileContainer__1OkCc{
    display: flex;
    align-items: center;
    
}

.HelpDoc_iconItem__126F3 {
    width: 20px;
    height: 20px;
    fill: inherit;
}

.HelpDoc_optionIcon__NyW75 {
    width: 14px;
    height: 14px;
    fill: inherit;
}
.MainContainer_rootContainer__ps1uC {
    height: calc(100vh);
    display: flex;
    flex-direction: column;
}

.MainContainer_navBar__17Ih8 {
    position: fixed;
    width: 100%;
    z-index: 1;
}
.MainPanel_rootContainer__2wWGS{
    width: 100%;
    height: 100%;
}
.HomePage_homeContainer__305ko {
    padding-top: 64px;
    color: #293447;
    background: #f1f3f7;
    box-sizing: border-box;
}

.HomePage_title__29u9D {
    font-size: 18px;
    font-weight: 600;
}

.HomePage_btnPrimary__1n6Gc {
    border-radius: 4px;
    padding: 0px 16px 0px 16px;
    background: #2966cc;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: center;
}

.HomePage_contentContainer__P-ams {
    padding-top: 48px;
    padding-bottom: 64px;
}


.HomePage_contentCenter__1MDB4 {
    display: flex;
    align-items: center;
    grid-gap: 48px;
    gap: 48px;
    flex-direction: column;
    padding-inline: 32px;
}

.HomePage_contentStart__3rDfM {
    display: flex;
    align-items: start;
    grid-gap: 48px;
    gap: 48px;
    flex-direction: column;
    padding-inline: 32px;
}

.HomePage_row__1xRbF {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    grid-gap: 32px;
    gap: 32px;
    flex-wrap: wrap;
}

.HomePage_col__1N07j {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
    border: none;
    flex-wrap: wrap;
}


.Header_header__3-xnF, .Header_homeHeader__3oZYr{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    height: 64px;
    padding-inline: 274px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.05),
      0px 2px 8px rgba(0, 0, 0, 0.05);
}

.Header_title__2gpbw, .Header_homeTitle__2ipAb{
    font-family: Poppins !important;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1C2430;
}

.Header_btnPrimary__1Fsc4 {
    border-radius: 4px;
    padding: 0px 16px 0px 16px;
    background: #2966cc;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: center;
    pointer-events: none;
}

.Header_homeHeader__3oZYr{
    padding-inline: 32px;
}

.Header_homeTitle__2ipAb{
    max-width: 1372px;
    flex: auto;
    margin-inline: auto;
}
.topCard_cardDesc__2Cm6E {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    height: 100%;
}

.topCard_cardLoaderContainer__oivXA {
    margin-left: 40px;
}

.topCard_errorMessage__2lFSP {
    color: red;
    font-size: 16px;
    font-weight: 600;
}

.topCard_cardAdditionalInfo__3-X-c {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #52698e;
}

.topCard_cardGraphic__1UDrj {
    display: flex;
    align-items: center;
    padding-top: 8px;
}

.topCard_vSeparator__3qcJv {
    width: 0px;
    height: 16px;
    border: 1px solid #b8c3d6;
}

.topCard_titleContainerWithIcon__SgqLy {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    align-items: center;
}

.topCard_cardIcon__OTeoP{
     display: flex;
 }

.topCard_cardTitle__2UeWA {
    font-weight: 600;
}

.topCard_titleContainerWithSubTitle__1cYVY {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.topCard_cardTitleOnly__T3F1Y {
    font-weight: 600;
}

.topCard_cardsubTitle__1xqIJ {
    font-weight: 500;
}

.topCard_card__1lacH {
    height: 135px !important;
    width: 436px;
    padding: unset !important;
}

.topCard_cardClickable__rVt1P{
    cursor: pointer;
}

.topCard_cardContainer__k0sSN{
    display: flex;
    padding: 16px 24px 24px 24px;
}
.topCard_cardContent__25uJ8 {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    width: 100%;
}

.topCard_cardLink__1ak6N {
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    color: #2966cc;
    text-decoration: underline;
}


.RecentBlogs_cardAuditInfoContainer__2BEpb{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 12px;

}

.RecentBlogs_cardAuditInfo__3HvRV{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 40px;
    width: 100%;
}

.RecentBlogs_auditInfoText__22jg0{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #293447;
    font-family: "Source Sans Pro";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
}
.middleCards_titleContainerWithIcon__1P7b5 {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    align-items: center;
}

.middleCards_cardIcon__3c3yo{
    display: flex;
}


.middleCards_cardTitle__2Zvr0 {
    font-weight: 600;
}

.middleCards_titleContainerWithSubTitle__1RoAi {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.middleCards_cardTitleOnly__2cpdb {
    font-size: 38px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #11161d;
}

.middleCards_cardsubTitle__vKNpS {
    font-family: Poppins !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #293447;
}

.middleCards_card__2WNvm {
    height: 360px !important;
    width: 436px;
    padding: 32px !important;
}

.middleCards_cardContainer__128Ai{
    display: flex;
    flex-direction: column;
    grid-gap: 18px;
    gap: 18px;
}


.middleCards_listContainer__zKiEn {
    padding: 0;
    display: flex;
    flex-direction: column;
}

.middleCards_emptyRecentlyUseRoot__3jtf7 {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.middleCards_emptyRecentlyUseText__3sG6g {
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
    color:  #293447;
    height: 20px;
}
.middleCards_emptyRecentlyUseSVG__3lm2P {
    margin-top: 48px;
    margin-bottom: 20px;
    margin-right: 75px;
    width: 64px;
    height: 64px;
}

.middleCards_manageProducts__JT52E {
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #2966cc;
    text-decoration: underline;
    margin-top: 75px;
}

.middleCards_manageProductsLink__24AmF {
     text-decoration: none;
     color: inherit;
 }

.middleCards_errorMessage__10efb {
    color: red;
    font-size: 16px;
    font-weight: 600;
}

.middleCards_cardLoaderContainer__3CrQq {
    margin-left: 40px;
}
.recentlyUsed_listData__Y5S9e,
.recentlyUsed_listData2__2OryV {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 16px;
    grid-gap: 16px;
    gap: 16px;
    border-bottom: 1px solid #b8c3d6;
    width: 100%;
    height: 72px;
    margin-top: 8px;
}

.recentlyUsed_listData2__2OryV {
    padding: 16px 0;
}

.recentlyUsed_listData__Y5S9e:last-child,
.recentlyUsed_listData2__2OryV:last-child {
    border-bottom: none;
}
.recentlyUsed_listIcon__1rlQh  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    height:48px;
    width: 48px;
}
.recentlyUsed_listIcon__1rlQh > img {

    height: 32px;
    width: 32px;
}

.recentlyUsed_listName__3kYqy {
    font-weight: 600;
}
.recentlyUsed_listDesc__1PFR0 {
    align-self: center;
    width: 308px;
    height: 16px;
    font-family: "Source Sans Pro" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #52698E;
}

.Footer_footerContainer__3uEav{
    border-top: 1px solid #DDE2E9;
    height: 41.5vh;
    background-color: #ffffff;
    padding-top: 40px;
    padding-inline: 196px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.05),
    0px 2px 8px rgba(0, 0, 0, 0.05);
}

.Footer_row__3KfZi{
    display: flex;
    justify-content: space-between;
}

.Footer_colHeading__2L2zU{
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
}

.Footer_colItems__1sRd5{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    width: -webkit-max-content;
    width: max-content;
}

.Footer_colLists__1waq-{
    list-style: none;
    padding: 0;
}

.Footer_social__30Z2i{
    padding-block: 40px;
}

.Footer_hzSaperater__1TSp1{
    height: 0px;
    left: calc(50% - 100%/2);
    top: 288px;
    border: 1px solid #DDE2E9;
}

.Footer_socialContainer__qo-46{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 44px;
}

.Footer_icon__3C_fw{
    padding-right: 16px;
    cursor: pointer;
}

.Footer_copyrights__3JAwR{
    padding-block: 40px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
}

.Footer_anchor__2by9y,.Footer_anchor__2by9y:visited,.Footer_anchor__2by9y:active,.Footer_anchor__2by9y:hover{
    color: #212A39;
    text-decoration: none;
}
.ProductLauncherPage_featchLoading__364U2{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins' !important;
    font-size: 16px;
    font-weight: 400;
}
.SynamediaCover_root__5ALRZ{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: black;
}

.SynamediaCover_container__3HA-H{
    width: 952px;
    height: 100vh;
    color: white;
}

.SynamediaCover_infoContainer__UyT5H {
    padding-top: 286px;
    margin-left: 104px;
}

.SynamediaCover_infoContainer__UyT5H > .SynamediaCover_title__KSBmR * {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0.03em;
    text-align: left;
    width: 512px;
}

.SynamediaCover_logo__1v4tX {
    padding-top: 16px;
    margin-left: 24px;
    font-size: 32px;
}

.SynamediaCover_infoContainer__UyT5H > .SynamediaCover_desc__3uuCw {
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: left;
    width: 586px;
    color: #b8c3d6;
    margin-top: 16px;
}

.SynamediaCover_trustContainer__2LotK {
    position: absolute;
    bottom: 0;
    width: 663.39px;
    margin-bottom: 36px;
    margin-left: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SynamediaCover_trustContainer__2LotK > .SynamediaCover_trustedTypography__1aYFf {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #d8dee9;
    width: 150px;
}

.SynamediaCover_trustContainer__2LotK > .SynamediaCover_companyIconsContainer__3WS9f {
    display: flex;
    align-items: center;
    grid-gap: 32px;
    gap: 32px;
}

.SynamediaCover_requestLink__1kn_R,
.SynamediaCover_requestLink__1kn_R:hover,
.SynamediaCover_requestLink__1kn_R:focus,
.SynamediaCover_requestLink__1kn_R:active{
    color: #ffffff !important;
    font-family: Source Sans Pro !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: left;
    text-decoration: underline;
}

.SynamediaCover_trialLink__2X0nM {
    color: white;
    margin-right: 5px;
}

.SynamediaCover_trialContainer__2fFya{
    display: flex;
    flex-direction: column;
    margin-top: 32px;
}

.SynamediaCover_trialLinkContainer__9JvAz{
    display: flex;
    flex-direction: row;
}
.forgotAccount_rootContainer__1siSy {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 420px;
    margin: 0 auto 16px auto;
}

.forgotAccount_formContainer__E59v2 {
    width: 392px;
}

.forgotAccount_titleContainer__2Ivgk {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    padding-bottom: 24px;
}

.forgotAccount_userInputContainer__4TVA2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
    margin-left: 24px;
}

.forgotAccount_setupAccountButton__-NQcu{
    width: 373px;
    height: 40px !important;
    font-weight: 400;
    margin-left: 24px;
    margin-bottom: 25px;
    font-family: 'Source Sans Pro';
}
.forgotAccount_titleTopContainer__3ls6Y {
    margin-bottom: 26px;
}
.forgotAccount_title__1NlDh {
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: #11161D;
    margin-bottom: 8px;
}
.forgotAccount_content__3oVik {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #293447;
    width: 85%;
    margin-bottom: 12px;
    /*margin-bottom: 12px;*/
}

.forgotAccount_label__14_sQ {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #293447;
}

.forgotAccount_input__1WRfq {
    width: 373px !important;
}

.forgotAccount_inputLabel__1jgSV {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #293447;
}

.forgotAccount_backToCol__1XMUw {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin-top: 12px;

}

.forgotAccount_backToRow__1lCPM {
    display: flex;
    flex-direction: row;
    margin-left: 36px;

}

.forgotAccount_signIn__3uYHf {
    color: #2966CC;
    margin-left: 3px;
    cursor: pointer
}

.forgotAccount_emailBoxContainer__2ARnt {
    display: flex;
    flex-direction: column;
    align-items:center
}
.messageSent_rootContainer__3064Y {
    display: flex;
    flex-direction: column;
    width: 450px;
    margin: 0 auto 120px auto;
    /*margin-right: 12px;*/
    /*margin-bottom: 120px;*/
}

.messageSent_formContainer__3EUdw {
    margin-top: 20px;
    width: 100%;
    height: 100px;
}

.messageSent_headerContainer__10_PS {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    height: 200px;
}
.messageSent_emailBoxContainer__1JZJ3 {
    display: flex;
    flex-direction: column;
    align-items:center;
    margin: 95px 0 36px 0;
}

.messageSent_setupAccountButton__1wWMN{
    width: 83%;
    height: 40px !important;
    font-weight: 400;
    margin-left: 40px;
    margin-bottom: 20px;
    font-family: 'Source Sans Pro';
}

.messageSent_title__25hvL {
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: #11161D;
    margin-bottom: 8px;
}

.messageSent_label__2mH2L {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #293447;
    margin-bottom: 24px;
    width: 100%;
}
.messageSent_content__PYHp9 {

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    font-height: 25px;
    text-align: center;
    color: #293447;
    margin-bottom: 4px;
}


.messageSent_backToCol__2V_Ac {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 22px;
}

.messageSent_backToRow__3SF7g {
    display: flex;
    flex-direction: row;
}

.messageSent_signIn__2D4vT {
    color: #2966CC;
    margin-left: 3px;
    cursor: pointer
}


