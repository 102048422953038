.productAdminRolesContainer{
    width: 100%;
    height: auto;
    background-color: var(--white);
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-4);
    gap: var(--spacing-2);
}

.productAdminRolesTitle{
    font-family: var(--font-families-poppins);
    font-size: var(--font-size-1);
    font-weight: 600;
    line-height: 22px;
    color: var(--text-text-primary);
}

.productAdminRolesTitleContainer{
    display: flex;
    gap: var(--spacing-2);
    align-items: center;
}

.rolesContainer{
    display: flex;
}
.productsRolesContainer{
    height: 100%;
    width: 100%;
    display: flex;
}
.rolesLabel{
    font-size: var(--font-size-2);
}
.rolesSelections{
    width: 100%;
}
.iconContainer{
    margin-left: 5;
}
.accordionSummary{
    display: flex;
    flex-direction: row-reverse;
}
.rotate{
    transform: rotate(-90deg);
}
.rotateSelected{
    transform: rotate(180deg);
}
.modify{
    margin-left: var(--spacing-2);
}
.labelContainer{
    display:flex;
    flex-direction: row;
    align-items: center
}