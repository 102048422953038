.footerContainer{
    border-top: 1px solid #DDE2E9;
    height: 41.5vh;
    background-color: #ffffff;
    padding-top: 40px;
    padding-inline: 196px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.05),
    0px 2px 8px rgba(0, 0, 0, 0.05);
}

.row{
    display: flex;
    justify-content: space-between;
}

.colHeading{
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
}

.colItems{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    width: max-content;
}

.colLists{
    list-style: none;
    padding: 0;
}

.social{
    padding-block: 40px;
}

.hzSaperater{
    height: 0px;
    left: calc(50% - 100%/2);
    top: 288px;
    border: 1px solid #DDE2E9;
}

.socialContainer{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 44px;
}

.icon{
    padding-right: 16px;
    cursor: pointer;
}

.copyrights{
    padding-block: 40px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
}

.anchor,.anchor:visited,.anchor:active,.anchor:hover{
    color: #212A39;
    text-decoration: none;
}