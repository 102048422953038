.rootContainer{
height: calc(100vh - 185px);
}

.deleteConfirmationBtb{
    display: flex;
    align-items: center;
}
.errorContainer{
    color: red;
    margin-left: 24px;
}

.userName{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #11161D;
}

.role{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #52698E;
}