.rootContainer {
    padding: 30px 30px 30px 32px;
    width: 100%;
    min-height: 900px;
    background-color: #D8DEE9
}

.cardContainer {
    width: 760px;
    height: 328px;
    border: 1px solid #D8DEE9;
    border-radius: 6px;
    background-color: #FFFFFF;
    padding: 24px
}

.title {
    color: #11161D;
    font-weight: 600;
    font-family: "Source Sans Pro";
    font-size: 16px;

}

.instruction {
    display: flex;
    flex-direction: row;
    margin-top: 12px

}

.rangeTitle {
    color: #455878;
    font-family: "Source Sans Pro";
    font-size: 12px;
}

.rangeRequire {
    color: #DA1E28;
    font-size: 12px;
}

.rangesContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 8px;
}

.rangeCardContainer {
    height: 52px;
    margin: 0 16px 0 0;
    padding: 6px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.actionsContainer {
    display: flex;
    justify-content: space-between;
}
.optionsContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 230px;
}

.loaderBackDrop {
    position: fixed;
    width: 100%;
    background: #29292980;
    z-index: 999;
    inset: 0;
    color: white;
    display: grid;
    place-items: center;
}

.row {
    display: flex;
    flex-direction: row;
}

.datePickerInput {
    width: 100%;
    height: 32px;
    border: 1px solid #D8DEE9;
    border-radius: 4px;
    padding: 7px 16px 7px 16px;
    position: absolute;
    grid-column-start: 1;
    grid-row-start: 1;
    top: -25px

}

.datePickerWrapper {
    width: 100%;
    grid-row: 1;
    grid-column: 1;
    background-color: white !important;
}
.datePickerLabel{
    font-family: "Source Sans Pro";
    color: #455878;
    font-size: 12px;
    margin-bottom: 12px;
}
.datePickerContainerStart{
    display: flex;
    flex-direction: column;
    width: 50%;

}
.datePickerContainerEnd{
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    width: 50%;

}

.grid{
    display: grid;
    grid-template-columns: 337px;
    grid-template-rows: auto;
    position: relative;
}

.calendarIcon {
    grid-column-start: 1;
    grid-row-start: 1;
    position: absolute;
    right: 20px;
    top: -3px

}

div[class='react-datepicker__header '] {
    background-color: white !important;
    border-bottom : none !important;
}
div[class='react-datepicker__triangle'] {
    display: none !important;
}
div[class='react-datepicker__current-month'] {
    color: #11161D !important;
    font-family: "Source Sans Pro" !important;
    font-weight: 600 !important;
    font-size: 16px !important;

}

div[class='react-datepicker__day-name'] {
    color: #455878 !important;
}

